import { Alert, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import InterventoForm from "../components/intervento/InterventoForm";
import TitleBar from "../components/shared/TitleBar";
import { CreateOrUpdateIntervento } from "../services/InterventiService";
import {
  LavorazioneArticoloFormModel,
  LavorazioneFormModel,
  LavorazioneTecnicoFormModel,
} from "../components/intervento/models";
import SyncService from "../services/SyncService";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

export default function AggiungiIntervento() {
  const [intervento, setIntervento] = useState<CreateOrUpdateIntervento>({
    numero: null,
    clienteId: null,
    inizio: dayjs(),
    fine: dayjs(),
    deleted: false,
    autoreId: null,
  });
  const [lavorazioni, setLavorazioni] = useState<LavorazioneFormModel[]>([]);
  const [lavorazioniTecnici, setLavorazioniTecnici] = useState<
    LavorazioneTecnicoFormModel[]
  >([]);
  const [lavorazioniArticoli, setLavorazioniArticoli] = useState<
    LavorazioneArticoloFormModel[]
  >([]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [creating, setCreating] = useState(false);
  const [createError, setCreateError] = useState<
    "unknownError" | "numeroNonUnico" | undefined
  >();
  async function doCreate() {
    setCreating(true);
    const createResult = await SyncService.sync(
      undefined,
      [],
      [],
      [],
      [],

      intervento,
      lavorazioni,
      lavorazioniTecnici,
      lavorazioniArticoli,
      []
    );
    await queryClient.invalidateQueries(["interventi"]);
    setCreating(false);
    if (createResult !== true) {
      setCreateError(createResult);
      return;
    }
    navigate("/interventi");
  }

  return (
    <Stack direction="column">
      <TitleBar
        title="Aggiungi intervento"
        actions={
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={creating || !intervento.clienteId}
            onClick={doCreate}
          >
            Aggiungi intervento
          </Button>
        }
      />

      {createError === "unknownError" && (
        <Alert severity="warning">
          Error durante il salvataggio dell'intervento, verifica la connessione
          a internet o prova ad aggiornare la pagina.
        </Alert>
      )}

      {createError === "numeroNonUnico" && (
        <Alert severity="warning">
          Esiste già un intervento con il numero {intervento.numero}.
        </Alert>
      )}

      <InterventoForm
        loading={creating}
        intervento={intervento}
        onInterventoChange={setIntervento}
        lavorazioni={lavorazioni}
        onLavorazioniChange={setLavorazioni}
        lavorazioniTecnici={lavorazioniTecnici}
        onLavorazioniTecniciChange={setLavorazioniTecnici}
        lavorazioniArticoli={lavorazioniArticoli}
        onLavorazioniArticoliChange={setLavorazioniArticoli}
      />
    </Stack>
  );
}

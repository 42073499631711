import { Button } from "@mui/material";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export type DeleteButtonProps = {
  confirmationQuestion: string;
  onDeletionConfirmed: () => void;
  disabled?: boolean;
  size?: "small";
};

export default function DeleteButton({
  confirmationQuestion,
  onDeletionConfirmed,
  disabled = false,
  size,
}: DeleteButtonProps) {
  function askConfirmation() {
    const confirmed = window.confirm(confirmationQuestion);
    if (confirmed) {
      onDeletionConfirmed();
    }
  }

  return (
    <Button
      size={size}
      disabled={disabled}
      variant="contained"
      color="error"
      startIcon={<DeleteForeverIcon />}
      onClick={askConfirmation}
    >
      Elimina
    </Button>
  );
}

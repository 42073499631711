import { useMutation, useQueryClient } from "@tanstack/react-query";
import InterventiService from "../services/InterventiService";

export default function useArchiviaInterventi() {
  const queryClient = useQueryClient();
  const { isLoading, mutate, error } = useMutation({
    mutationFn: async (ids: number[]) => {
      await InterventiService.archiviaInterventi(ids);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(["interventi"]);
    },
  });
  return {
    isLoading,
    archiviaInterventi: mutate,
    error,
  };
}

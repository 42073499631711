class ArticoliNonCatalogatiService {
  async getAll(): Promise<"unknownError" | ArticoloNonCatalogato[]> {
    try {
      const res = await fetch("/api/articoliNonCatalogati");
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export type ArticoloNonCatalogato = {
  id: number;
  codiceDanea: string | null;
  descrizione: string | null;
  immaginiIds: number[];
};

export default new ArticoliNonCatalogatiService();

import dayjs, { Dayjs } from "dayjs";
import { prepareDateForRequestDto } from "./adapters";

class InterventiService {
  async getAll(): Promise<"unknownError" | InterventoWithTecniciIds[]> {
    try {
      const res = await fetch("/api/interventi?includeTecniciIds=true");
      const json = await res.json();
      return json.map((i: any) => ({
        ...i,
        inizio: dayjs(i.inizio),
        fine: i.fine ? dayjs(i.fine) : null,
      }));
    } catch (e) {
      return "unknownError";
    }
  }

  async get(id: number): Promise<"notFound" | "unknownError" | Intervento> {
    try {
      const res = await fetch(`/api/interventi/${id}`);
      if (res.status === 404) {
        return "notFound";
      }
      const intervento = await res.json();
      intervento.inizio = dayjs(intervento.inizio);
      intervento.fine = intervento.fine && dayjs(intervento.fine);
      return intervento;
    } catch (e) {
      return "unknownError";
    }
  }

  async create(
    dto: CreateOrUpdateIntervento
  ): Promise<"unknownError" | "numeroNonUnico" | Intervento> {
    try {
      const res = await fetch(`/api/interventi`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prepareDto(dto)),
      });
      if (res.status === 409) {
        return "numeroNonUnico";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: CreateOrUpdateIntervento
  ): Promise<"unknownError" | "numeroNonUnico" | Intervento> {
    try {
      const res = await fetch(`/api/interventi/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prepareDto(dto)),
      });
      if (res.status === 409) {
        return "numeroNonUnico";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async archiviaInterventi(ids: number[]) {
    const res = await fetch(`/api/interventi/archivia`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ids),
    });
    if (res.status !== 200) {
      throw new Error("archivia interventi failed");
    }
  }

  async eliminaInterventi(ids: number[]) {
    const res = await fetch(`/api/interventi/elimina`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ids),
    });
    if (res.status !== 200) {
      throw new Error("elimina interventi failed");
    }
  }

  async segnaComeVistoDalWeb(intervento: Intervento) {
    const res = await fetch(`/api/interventi/${intervento.id}/vistoDaWeb`, {
      method: "post",
    });
    if (res.status !== 200) {
      throw new Error("segna come visto da web failed");
    }
  }
}

export function isInterventoModificatoDaTablet(intervento: Intervento) {
  if (!intervento.ultimaModificaDaTablet) {
    return false;
  }
  if (!intervento.ultimaVisualizzazioneDaWeb) {
    return true;
  }
  return (
    intervento.ultimaModificaDaTablet > intervento.ultimaVisualizzazioneDaWeb
  );
}

function prepareDto(dto: CreateOrUpdateIntervento) {
  return {
    ...dto,
    inizio: dto.inizio && prepareDateForRequestDto(dto.inizio),
    fine: dto.fine && prepareDateForRequestDto(dto.fine),
  };
}

export type Intervento = {
  id: number;
  numero: number | null;
  clienteId: number;
  inizio: Dayjs;
  fine: Dayjs | null;
  //ultimaModifica: LocalDateTime;
  autoreId: number;
  deleted: boolean;
  archived: boolean;
  ultimaModificaDaTablet: string | null;
  ultimaVisualizzazioneDaWeb: string | null;
};

export type CreateOrUpdateIntervento = {
  numero: number | null;
  clienteId: number | null;
  inizio: Dayjs | null;
  fine: Dayjs | null;
  deleted: boolean;
  autoreId: number | null;
};

export type InterventoWithTecniciIds = Intervento & {
  tecniciIds: number[];
};

export default new InterventiService();

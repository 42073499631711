import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GroupsIcon from "@mui/icons-material/Groups";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Link, Outlet, useMatch } from "react-router-dom";
import logo from "./images/logo.png";
import SessioneService from "./services/SessioneService";

const drawerWidth = 240;
const appBarHeight = 64;

export default function LoggedInLayout() {
  const [logoutLoading, setLogoutLoading] = useState(false);
  async function handleLogout() {
    setLogoutLoading(true);
    await SessioneService.logout();
    window.location.reload();
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" sx={{ zIndex: 10000 }}>
        <Toolbar>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Interventi elettrici
          </Typography>
          <Button
            variant="outlined"
            color="inherit"
            disabled={logoutLoading}
            onClick={handleLogout}
          >
            Esci
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: `${drawerWidth}px`,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            marginTop: `${appBarHeight}px`,
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 64px)",
          }}
        >
          <List>
            <Divider />
            <ListItemButton
              component={Link}
              to="/interventi"
              selected={!!useMatch("/interventi/*")}
            >
              <ListItemIcon>
                <WorkIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Interventi" />
            </ListItemButton>

            <Divider />

            <ListItemButton
              component={Link}
              to="/articoli"
              selected={!!useMatch("/articoli/*")}
            >
              <ListItemIcon>
                <LocalOfferIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Articoli" />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/clienti"
              selected={!!useMatch("/clienti/*")}
            >
              <ListItemIcon>
                <GroupsIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Clienti" />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/tecnici"
              selected={!!useMatch("/tecnici/*")}
            >
              <ListItemIcon>
                <EngineeringIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Tecnici" />
            </ListItemButton>
          </List>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1em",
            }}
          >
            <img src={logo} width="192px" alt="Ghidini Fabio" />
          </div>
        </div>
      </Drawer>
      <Box sx={{ marginTop: `${appBarHeight}px`, flexGrow: 1, padding: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
}

import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ImportDelegate, useImportDelegate } from "../hooks/useImportDelegate";

export type ImportDialogTexts = {
  title: string;
  itemSingularName: string;
  itemPluralName: string;
};

export type ImportDialogProps<T> = {
  onClose: () => void;
  file?: File;
  texts: ImportDialogTexts;
  delegate: ImportDelegate<T>;
};

function ImportDialog<T>({
  onClose,
  file,
  texts,
  delegate,
}: ImportDialogProps<T>) {
  const { state, doImport } = useImportDelegate<T>(delegate, file);
  return (
    <Dialog open={file !== undefined} maxWidth="sm" fullWidth>
      <DialogTitle>{texts.title}</DialogTitle>
      {state.name === "readingFile" && <ReadingFileDialogContent />}
      {state.name === "readError" && (
        <ReadingFileErrorDialogContent
          onClose={onClose}
          missingHeader={state.missingHeader}
        />
      )}
      {state.name === "readSuccess" && (
        <ReadingFileSuccessDialogContent
          doImport={() => doImport(state.items)}
          onClose={onClose}
          items={state.items}
          isItemNew={delegate.isItemNew}
          itemSingularName={texts.itemSingularName}
          itemPluralName={texts.itemPluralName}
        />
      )}
      {state.name === "importing" && <ImportingDialogContent />}
      {state.name === "importError" && (
        <ImportingErrorDialogContent onClose={onClose} />
      )}
      {state.name === "importSuccess" && (
        <ImportSuccessDialogContent onClose={onClose} />
      )}
    </Dialog>
  );
}

function ReadingFileDialogContent() {
  return (
    <DialogContent>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <CircularProgress size={60} />
        <Typography>Lettura file in corso ...</Typography>
      </Stack>
    </DialogContent>
  );
}

function ReadingFileErrorDialogContent({
  onClose,
  missingHeader,
}: {
  onClose: () => void;
  missingHeader?: string;
}) {
  return (
    <>
      <DialogContent>
        {missingHeader ? (
          <Alert severity="warning">
            Errore durante la lettura del file: non è stata trovata la colonna{" "}
            <strong>{missingHeader}</strong>.
          </Alert>
        ) : (
          <Alert severity="warning">
            Si è verificato un errore durante la lettura del file.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Chiudi
        </Button>
      </DialogActions>
    </>
  );
}

function ReadingFileSuccessDialogContent<T>({
  doImport,
  onClose,
  items,
  isItemNew,
  itemSingularName,
  itemPluralName,
}: {
  doImport: () => void;
  onClose: () => void;
  items: T[];
  isItemNew: (item: T) => boolean;
  itemSingularName: string;
  itemPluralName: string;
}) {
  const totalItemsCount = items.length;
  const newItemsCount = items.filter((i) => isItemNew(i)).length;
  const existingItemsCount = totalItemsCount - newItemsCount;
  return (
    <>
      <DialogContent>
        {totalItemsCount === 0 ? (
          <Alert severity="warning">
            Il file selezionato sembra essere vuoto. Prova a verificare il file
            selezionato.
          </Alert>
        ) : (
          <Stack spacing={2}>
            <Typography>
              Il file è stato letto correttamente e contiene:
            </Typography>
            <Typography>
              <strong>{newItemsCount}</strong>
              {newItemsCount === 1
                ? ` nuovo ${itemSingularName};`
                : ` nuovi ${itemPluralName};`}
            </Typography>
            <Typography>
              <strong>{existingItemsCount}</strong>
              {existingItemsCount === 1
                ? ` ${itemSingularName} da aggiornare;`
                : ` ${itemPluralName} da aggiornare;`}
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annulla
        </Button>
        {totalItemsCount > 0 && (
          <Button variant="outlined" color="primary" onClick={doImport}>
            Importa
          </Button>
        )}
      </DialogActions>
    </>
  );
}

function ImportingDialogContent() {
  return (
    <DialogContent>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <CircularProgress size={60} />
        <Typography>Invio dei dati al server in corso ...</Typography>
      </Stack>
    </DialogContent>
  );
}

function ImportingErrorDialogContent({ onClose }: { onClose: () => void }) {
  return (
    <>
      <DialogContent>
        <Alert severity="warning">
          Il file è stato letto correttamente, tuttavia si è verificato un
          errore durante l'invio al server dei dati. Verifica la connessione a
          internet e riprova.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Chiudi
        </Button>
      </DialogActions>
    </>
  );
}

function ImportSuccessDialogContent({ onClose }: { onClose: () => void }) {
  return (
    <>
      <DialogContent>
        <Alert severity="success">
          Il file è stato importato correttamente!
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Chiudi
        </Button>
      </DialogActions>
    </>
  );
}

export function useImportDialog<T>(
  texts: ImportDialogTexts,
  delegate: ImportDelegate<T>
) {
  const [file, setFile] = useState<File | undefined>();
  return {
    openDialog: (file: File) => {
      setFile(file);
    },
    dialog: (
      <ImportDialog
        file={file}
        texts={texts}
        delegate={delegate}
        onClose={() => setFile(undefined)}
      />
    ),
  };
}

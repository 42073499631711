import { Intervento } from "./services/InterventiService";
import { Cliente } from "./services/ClientiService";

export function getInterventoTitleFromClienti(
  intervento: Intervento,
  clienti: Cliente[]
) {
  const cliente = clienti.find((c) => c.id === intervento.clienteId);
  return cliente ? getInterventoTitle(intervento, cliente) : "";
}

export function getInterventoTitle(intervento: Intervento, cliente: Cliente) {
  return intervento.numero
    ? `${intervento.numero} - ${cliente.denominazione}`
    : `${cliente.denominazione}`;
}

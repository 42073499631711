import { CreateOrUpdateClienteDto } from "./ClientiService";
import { read, utils } from "xlsx";

export async function parseImportClientiFile(
  file: File
): Promise<CreateOrUpdateClienteDto[] | { missingHeader: string } | false> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = read(arrayBuffer);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const rows = utils.sheet_to_json(worksheet, { defval: "" });

    const missingHeader = checkExpectedColumns(rows[0]);
    if (typeof missingHeader === "string") {
      return { missingHeader };
    }

    return rows.map((row: any) => {
      return {
        codiceDanea: row["Cod."],
        denominazione: row["Denominazione"],
        codiceFiscale: row["Codice fiscale"],
        partitaIva: row["Partita Iva"],
        indirizzo: row["Indirizzo"],
        cap: parseInt(row["Cap"]) || null,
        citta: row["Città"],
        deleted: false,
      };
    });
  } catch (e) {
    console.error("parseImportClientiFile", e);
    return false;
  }
}

function checkExpectedColumns(aRow: any): true | string {
  const headers = Object.keys(aRow);
  const requiredHeaders = [
    "Cod.",
    "Codice fiscale",
    "Partita Iva",
    "Denominazione",
    "Indirizzo",
    "Cap",
    "Città",
    "Prov.",
  ];
  for (const header of requiredHeaders) {
    if (!headers.includes(header)) {
      return header;
    }
  }
  return true;
}

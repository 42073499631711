import { Stack, Typography } from "@mui/material";
import DettagliInterventoForm from "./DettagliInterventoForm";
import LavorazioneForm from "./LavorazioneForm";
import { CreateOrUpdateIntervento } from "../../services/InterventiService";
import TextComment from "../shared/TextComment";
import {
  LavorazioneArticoloFormModel,
  LavorazioneFormModel,
  LavorazioneTecnicoFormModel,
  makeArrayUpdater,
  makeFilteredArrayUpdater,
} from "./models";
import CenteredButton from "../../components/CenteredButton";
import LocalIdService from "../../services/LocalIdService";
import { LavorazioneArticoloNonCatalogato } from "../../services/LavorazioniArticoliNonCatalogatiService";

export type InterventoFormProps = {
  loading?: boolean;
  intervento: CreateOrUpdateIntervento | undefined;
  onInterventoChange: (i: CreateOrUpdateIntervento) => void;
  lavorazioni?: LavorazioneFormModel[];
  onLavorazioniChange: (l: LavorazioneFormModel[]) => void;
  lavorazioniTecnici?: LavorazioneTecnicoFormModel[];
  onLavorazioniTecniciChange: (l: LavorazioneTecnicoFormModel[]) => void;
  lavorazioniArticoli?: LavorazioneArticoloFormModel[];
  onLavorazioniArticoliChange: (l: LavorazioneArticoloFormModel[]) => void;
  lavorazioniArticoliNonCatalogati?: LavorazioneArticoloNonCatalogato[];
  onLavorazioniArticoliNonCatalogatiChange?: (
    l: LavorazioneArticoloNonCatalogato[]
  ) => void;
};

export default function InterventoForm({
  loading = false,
  intervento,
  onInterventoChange,
  lavorazioni,
  onLavorazioniChange,
  lavorazioniTecnici,
  onLavorazioniTecniciChange,
  lavorazioniArticoli,
  onLavorazioniArticoliChange,
  lavorazioniArticoliNonCatalogati,
  onLavorazioniArticoliNonCatalogatiChange,
}: InterventoFormProps) {
  const lavorazioniNotDeleted = lavorazioni?.filter((l) => !l.deleted);
  return (
    <Stack direction="column" spacing={2}>
      <DettagliInterventoForm
        loading={loading}
        intervento={intervento}
        onInterventoChange={onInterventoChange}
      />

      <Typography variant="h6" gutterBottom>
        Lavorazioni
      </Typography>

      {lavorazioniNotDeleted?.length === 0 && (
        <TextComment>
          Questo intervento non contiene nessuna lavorazione
        </TextComment>
      )}
      {lavorazioniNotDeleted?.map((l) => (
        <LavorazioneForm
          key={l.localId}
          lavorazione={l}
          onLavorazioneChange={makeArrayUpdater(
            lavorazioni!,
            onLavorazioniChange
          )}
          lavorazioneTecnici={lavorazioniTecnici?.filter(
            (lt) => lt.localLavorazioneId === l.localId
          )}
          onLavorazioneTecniciChange={makeFilteredArrayUpdater(
            lavorazioniTecnici,
            onLavorazioniTecniciChange,
            "localLavorazioneId",
            l.localId
          )}
          lavorazioneArticoli={lavorazioniArticoli?.filter(
            (la) => la.localLavorazioneId === l.localId
          )}
          onLavorazioneArticoliChange={makeFilteredArrayUpdater(
            lavorazioniArticoli,
            onLavorazioniArticoliChange,
            "localLavorazioneId",
            l.localId
          )}
          lavorazioneArticoliNonCatalogati={lavorazioniArticoliNonCatalogati?.filter(
            (lanc) => lanc.lavorazioneId === l.serverId
          )}
          onLavorazioneArticoliNonCatalogatiChange={
            onLavorazioniArticoliNonCatalogatiChange &&
            makeFilteredArrayUpdater(
              lavorazioniArticoliNonCatalogati,
              onLavorazioniArticoliNonCatalogatiChange,
              "lavorazioneId",
              l.serverId!
            )
          }
          canBeDeleted={lavorazioniNotDeleted?.length > 1}
        />
      ))}

      <CenteredButton
        onClick={
          lavorazioni &&
          (() =>
            onLavorazioniChange([
              ...lavorazioni,
              {
                localId: LocalIdService.nextId(),
                nome: "",
                note: "",
                deleted: false,
              },
            ]))
        }
      >
        Aggiungi lavorazione
      </CenteredButton>
    </Stack>
  );
}

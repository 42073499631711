class CredenzialiDiAccessoService {
  async getAll(): Promise<"unknownError" | CredenzialeDiAccesso[]> {
    try {
      const res = await fetch("/api/credenzialiDiAccesso");
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export type CredenzialeDiAccesso = {
  id: number;
  nomeUtente: string;
};

export default new CredenzialiDiAccessoService();

import { Typography } from "@mui/material";
import { ReactNode } from "react";

export type TextCommentProps = { children: ReactNode };

export default function TextComment({ children }: TextCommentProps) {
  return (
    <Typography
      style={{
        fontStyle: "italic",
        color: "gray",
        width: "100%",
        textAlign: "center",
      }}
      gutterBottom
    >
      {children}
    </Typography>
  );
}

import { Button } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export type FilePickerButtonProps = {
  onPickFile: (file: File) => void;
};

export default function FilePickerButton({
  onPickFile,
}: FilePickerButtonProps) {
  return (
    <Button variant="outlined" component="label" startIcon={<FileUploadIcon />}>
      Importa da Excel
      <input
        hidden
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        type="file"
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length === 1) {
            onPickFile(files[0]);
          }
        }}
      />
    </Button>
  );
}

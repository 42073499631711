import { Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";

export type TitleBarProps = {
  title: string;
  actions?: ReactNode;
};

export default function TitleBar({ title, actions }: TitleBarProps) {
  return (
    <Stack direction="row" spacing={2} mb={2}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <div style={{ flexGrow: 1 }} />
      {actions}
    </Stack>
  );
}

import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { useMakeFieldProps } from "../hooks/useMakeFieldProps";
import { CreateOrUpdateTecnicoDto } from "../services/TecniciService";

export type TecnicoFormProps = {
  loading?: boolean;
  tecnico: CreateOrUpdateTecnicoDto | undefined;
  onTecnicoChange: (a: CreateOrUpdateTecnicoDto) => void;
};

export default function TecnicoForm({
  loading = false,
  tecnico,
  onTecnicoChange,
}: TecnicoFormProps) {
  const { makeTextFieldProps } = useMakeFieldProps(
    tecnico,
    onTecnicoChange,
    loading
  );

  return (
    <Card style={{ position: "relative" }}>
      {loading && (
        <LinearProgress style={{ position: "absolute", left: 0, right: 0 }} />
      )}
      <CardContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Nome"
                fullWidth
                size="small"
                {...makeTextFieldProps("nome")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Cognome"
                fullWidth
                size="small"
                {...makeTextFieldProps("cognome")}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

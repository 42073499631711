import { CreateOrUpdateArticoloDto } from "./ArticoliService";
import { read, utils } from "xlsx";

export async function parseImportArticoliFile(
  file: File
): Promise<CreateOrUpdateArticoloDto[] | { missingHeader: string } | false> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = read(arrayBuffer);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const rows = utils.sheet_to_json(worksheet, { defval: "" });

    const missingHeader = checkExpectedColumns(rows[0]);
    if (typeof missingHeader === "string") {
      return { missingHeader };
    }

    return rows.map((row: any) => {
      return {
        codiceDanea: row["Cod."],
        descrizione: row["Descrizione"],
        codiceUdm: row["Cod. Udm"],
        prezzoListinoCentesimi: parseInt(`${row["Listino 1"] * 100}`) || 0,
        produttore: row["Produttore"],
        deleted: false,
      };
    });
  } catch (e) {
    return false;
  }
}

function checkExpectedColumns(aRow: any): true | string {
  const headers = Object.keys(aRow);
  const requiredHeaders = [
    "Cod.",
    "Descrizione",
    "Cod. Udm",
    "Listino 1",
    "Produttore",
  ];
  for (const header of requiredHeaders) {
    if (!headers.includes(header)) {
      return header;
    }
  }
  return true;
}

class LavorazioneArticoliService {
  async getByInterventoId(
    interventoId: number
  ): Promise<"unknownError" | LavorazioneArticolo[]> {
    try {
      const res = await fetch(
        `/api/lavorazioniArticoli?interventoId=${interventoId}`
      );
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async create(
    dto: CreateOrUpdateLavorazioneArticoloDto
  ): Promise<"unknownError" | LavorazioneArticolo> {
    try {
      const res = await fetch(`/api/lavorazioniArticoli`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: CreateOrUpdateLavorazioneArticoloDto
  ): Promise<"unknownError" | LavorazioneArticolo> {
    try {
      const res = await fetch(`/api/lavorazioniArticoli/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export default new LavorazioneArticoliService();

export type LavorazioneArticolo = {
  id: number;
  lavorazioneId: number;
  articoloId: number | null;
  n: number | null;
  deleted: boolean;
};

export type CreateOrUpdateLavorazioneArticoloDto = {
  lavorazioneId: number;
  articoloId: number | null;
  n: number | null;
  deleted: boolean | null;
};

import { useNavigate, useParams } from "react-router-dom";

export default function useIdParamFromRouter(): number {
  const navigate = useNavigate();
  const { id } = useParams();
  const parsedId = id && parseInt(id);
  if (!parsedId || isNaN(parsedId)) {
    navigate("/");
    return 0;
  }
  return parsedId;
}

import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import { CreateOrUpdateArticoloDto } from "../services/ArticoliService";
import React from "react";
import { useMakeFieldProps } from "../hooks/useMakeFieldProps";
import { PrezzoInCentesimiField } from "./shared/numberFields";

export type ArticoloFormProps = {
  loading?: boolean;
  articolo: CreateOrUpdateArticoloDto | undefined;
  onArticoloChange: (a: CreateOrUpdateArticoloDto) => void;
  codiceDaneaAlreadyUsed?: boolean;
};

export default function ArticoloForm({
  loading = false,
  articolo,
  onArticoloChange,
  codiceDaneaAlreadyUsed = false,
}: ArticoloFormProps) {
  const { makeTextFieldProps, makeNumberFieldProps } = useMakeFieldProps(
    articolo,
    onArticoloChange,
    loading
  );

  return (
    <Card style={{ position: "relative" }}>
      {loading && (
        <LinearProgress style={{ position: "absolute", left: 0, right: 0 }} />
      )}
      <CardContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Codice Danea"
                fullWidth
                size="small"
                {...makeTextFieldProps("codiceDanea")}
                error={codiceDaneaAlreadyUsed}
                helperText={
                  codiceDaneaAlreadyUsed &&
                  "Questo codice Danea è già assegnato ad un altro articolo"
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Unità di misura"
                fullWidth
                size="small"
                {...makeTextFieldProps("codiceUdm")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Descrizione"
                fullWidth
                size="small"
                {...makeTextFieldProps("descrizione")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Produttore"
                fullWidth
                size="small"
                {...makeTextFieldProps("produttore")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PrezzoInCentesimiField
                {...makeNumberFieldProps("prezzoListinoCentesimi")}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

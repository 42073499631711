class LavorazioniTecniciService {
  async getByInterventoId(
    interventoId: number
  ): Promise<"unknownError" | LavorazioneTecnico[]> {
    try {
      const res = await fetch(
        `/api/lavorazioniTecnici?interventoId=${interventoId}`
      );
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async create(
    dto: CreateOrUpdateLavorazioneTecnicoDto
  ): Promise<"unknownError" | LavorazioneTecnico> {
    try {
      const res = await fetch(`/api/lavorazioniTecnici`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: CreateOrUpdateLavorazioneTecnicoDto
  ): Promise<"unknownError" | LavorazioneTecnico> {
    try {
      const res = await fetch(`/api/lavorazioniTecnici/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export default new LavorazioniTecniciService();

export type LavorazioneTecnico = {
  id: number;
  lavorazioneId: number;
  tecnicoId: number | null;
  oreDiLavoro: number | null;
  deleted: boolean;
};

export type CreateOrUpdateLavorazioneTecnicoDto = {
  lavorazioneId: number;
  tecnicoId: number | null;
  oreDiLavoro: number | null;
  deleted: boolean | null;
};

import InterventiService, {
  CreateOrUpdateIntervento,
  Intervento,
} from "./InterventiService";
import {
  LavorazioneArticoloFormModel,
  LavorazioneFormModel,
  LavorazioneTecnicoFormModel,
} from "../components/intervento/models";
import LavorazioniService, { Lavorazione } from "./LavorazioniService";
import LavorazioniTecniciService, {
  LavorazioneTecnico,
} from "./LavorazioniTecniciService";
import LavorazioniArticoliService, {
  LavorazioneArticolo,
} from "./LavorazioniArticoliService";
import LavorazioniArticoliNonCatalogatiService, {
  LavorazioneArticoloNonCatalogato,
} from "./LavorazioniArticoliNonCatalogatiService";
import { isEqual } from "lodash";

class SyncService {
  async sync(
    initialIntervento: CreateOrUpdateIntervento | undefined,
    initialLavorazioni: Lavorazione[],
    initialLavorazioniTecnici: LavorazioneTecnico[],
    initialLavorazioniArticoli: LavorazioneArticolo[],
    initialLavorazioneArticoliNonCatalogati: LavorazioneArticoloNonCatalogato[],

    intervento: Intervento | CreateOrUpdateIntervento,
    lavorazioni: LavorazioneFormModel[],
    lavorazioniTecnici: LavorazioneTecnicoFormModel[],
    lavorazioniArticoli: LavorazioneArticoloFormModel[],
    lavorazioneArticoliNonCatalogati: LavorazioneArticoloNonCatalogato[]
  ): Promise<true | "unknownError" | "numeroNonUnico"> {
    let interventoId: number;
    if ("id" in intervento) {
      const hasChanged = !isEqual(initialIntervento, intervento);
      if (hasChanged) {
        const updateResult = await InterventiService.update(
          intervento.id,
          intervento
        );
        if (
          updateResult === "unknownError" ||
          updateResult === "numeroNonUnico"
        ) {
          return updateResult;
        }
      } else {
        console.debug("no changes detected for intervento");
      }

      interventoId = intervento.id;
    } else {
      const creationResult = await InterventiService.create(intervento);
      if (
        creationResult === "unknownError" ||
        creationResult === "numeroNonUnico"
      ) {
        return creationResult;
      }
      interventoId = creationResult.id;
    }

    for (const lavorazione of lavorazioni) {
      if (lavorazione.serverId) {
        const hasChanged = !isEqual(
          initialLavorazioni.find((l) => l.id === lavorazione.serverId) || {},
          lavorazione
        );
        if (hasChanged) {
          const updateResult = await LavorazioniService.update(
            lavorazione.serverId,
            {
              interventoId,
              ...lavorazione,
            }
          );
          if (updateResult === "unknownError") {
            return updateResult;
          }
        } else {
          console.debug(
            `no changes detected for lavorazione ${lavorazione.serverId}`
          );
        }
      } else {
        if (lavorazione.deleted) {
          continue;
        }
        const creationResult = await LavorazioniService.create({
          interventoId,
          ...lavorazione,
        });
        if (creationResult === "unknownError") {
          return creationResult;
        }
        lavorazione.serverId = creationResult.id;
      }
    }

    for (const lavorazioneTecnico of lavorazioniTecnici) {
      const lavorazione = lavorazioni.find(
        (l) => lavorazioneTecnico.localLavorazioneId === l.localId
      );
      if (!lavorazione || !lavorazione.serverId) {
        return "unknownError";
      }

      if (lavorazioneTecnico.serverId) {
        const hasChanged = !isEqual(
          initialLavorazioniTecnici.find(
            (lt) => lt.id === lavorazioneTecnico.serverId
          ),
          lavorazioneTecnico
        );
        if (hasChanged) {
          const updateResult = await LavorazioniTecniciService.update(
            lavorazioneTecnico.serverId,
            { lavorazioneId: lavorazione.serverId, ...lavorazioneTecnico }
          );
          if (updateResult === "unknownError") {
            return updateResult;
          }
        } else {
          console.debug(
            `no changes detected for lavorazioneTecnico ${lavorazioneTecnico.serverId}`
          );
        }
      } else {
        if (lavorazioneTecnico.deleted || !lavorazioneTecnico.tecnicoId) {
          continue;
        }
        const creationResult = await LavorazioniTecniciService.create({
          lavorazioneId: lavorazione.serverId,
          ...lavorazioneTecnico,
        });
        if (creationResult === "unknownError") {
          return creationResult;
        }
        lavorazioneTecnico.serverId = creationResult.id;
      }
    }

    for (const lavorazioneArticolo of lavorazioniArticoli) {
      const lavorazione = lavorazioni.find(
        (l) => lavorazioneArticolo.localLavorazioneId === l.localId
      );
      if (!lavorazione || !lavorazione.serverId) {
        return "unknownError";
      }

      if (lavorazioneArticolo.serverId) {
        const hasChanged = !isEqual(
          initialLavorazioniArticoli.find(
            (la) => la.id === lavorazioneArticolo.serverId
          ),
          lavorazioneArticolo
        );
        if (hasChanged) {
          const updateResult = await LavorazioniArticoliService.update(
            lavorazioneArticolo.serverId,
            { lavorazioneId: lavorazione.serverId, ...lavorazioneArticolo }
          );
          if (updateResult === "unknownError") {
            return updateResult;
          }
        } else {
          console.debug(
            `no changes detected for lavorazioneArticolo ${lavorazioneArticolo.serverId}`
          );
        }
      } else {
        if (lavorazioneArticolo.deleted || !lavorazioneArticolo.articoloId) {
          continue;
        }
        const creationResult = await LavorazioniArticoliService.create({
          lavorazioneId: lavorazione.serverId,
          ...lavorazioneArticolo,
        });
        if (creationResult === "unknownError") {
          return creationResult;
        }
        lavorazioneArticolo.serverId = creationResult.id;
      }
    }

    for (const lavorazioneArticoloNonCatalogato of lavorazioneArticoliNonCatalogati) {
      const hasChanged = !isEqual(
        initialLavorazioneArticoliNonCatalogati.find(
          (lanc) => lanc.id === lavorazioneArticoloNonCatalogato.id
        ),
        lavorazioneArticoloNonCatalogato
      );
      if (hasChanged) {
        const updateResult =
          await LavorazioniArticoliNonCatalogatiService.update(
            lavorazioneArticoloNonCatalogato.id,
            lavorazioneArticoloNonCatalogato
          );
        if (updateResult === "unknownError") {
          return updateResult;
        }
      } else {
        console.debug(
          `no changes detected for lavorazioneArticoloNonCatalogato ${lavorazioneArticoloNonCatalogato.id}`
        );
      }
    }

    return true;
  }
}

export default new SyncService();

import React, { useEffect, useState } from "react";
import SessioneService, { SessioneDto } from "./services/SessioneService";
import LoginPage from "./pages/LoginPage";
import LoggedInLayout from "./LoggedInLayout";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ListaArticoli from "./pages/ListaArticoli";
import ListaTecnici from "./pages/ListaTecnici";
import ListaClienti from "./pages/ListaClienti";
import AggiungiArticolo from "./pages/AggiungiArticolo";
import ModificaArticolo from "./pages/ModificaArticolo";
import AggiungiCliente from "./pages/AggiungiCliente";
import ModificaCliente from "./pages/ModificaCliente";
import AggiungiTecnico from "./pages/AggiungiTecnico";
import ModificaTecnico from "./pages/ModificaTecnico";
import AggiungiIntervento from "./pages/AggiungiIntervento";
import ListaInterventi from "./pages/ListaInterventi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/it";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ModificaIntervento from "./pages/ModificaIntervento";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Rome");

const queryClient = new QueryClient();

export default function App() {
  const [sessione, setSessione] = useState<"loading" | null | SessioneDto>(
    "loading"
  );

  useEffect(() => {
    (async () => {
      const result = await SessioneService.getSession();
      setSessione(result);
    })();
  }, []);

  return (
    <MuiWrapper>
      <QueryClientProvider client={queryClient}>
        {sessione === "loading" && <div>Caricamento in corso ...</div>}
        {sessione === null && <LoginPage onLoggedIn={setSessione} />}
        {sessione !== null && typeof sessione === "object" && (
          <RouterProvider router={router} />
        )}
      </QueryClientProvider>
      <ToastContainer />
    </MuiWrapper>
  );
}

function MuiWrapper({ children }: { children: React.ReactNode }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#E0DE18",
      },
      secondary: {
        main: "#f50057",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoggedInLayout />,
    children: [
      { path: "*", element: <Navigate to="/interventi" /> },
      { path: "", element: <Navigate to="/interventi" /> },
      {
        path: "interventi",
        element: <ListaInterventi />,
      },
      {
        path: "interventi/new",
        element: <AggiungiIntervento />,
      },
      {
        path: "interventi/:id",
        element: <ModificaIntervento />,
      },
      {
        path: "articoli",
        element: <ListaArticoli />,
      },
      {
        path: "articoli/new",
        element: <AggiungiArticolo />,
      },
      {
        path: "articoli/:id",
        element: <ModificaArticolo />,
      },
      {
        path: "clienti",
        element: <ListaClienti />,
      },
      {
        path: "clienti/new",
        element: <AggiungiCliente />,
      },
      {
        path: "clienti/:id",
        element: <ModificaCliente />,
      },
      {
        path: "tecnici",
        element: <ListaTecnici />,
      },
      {
        path: "tecnici/new",
        element: <AggiungiTecnico />,
      },
      {
        path: "tecnici/:id",
        element: <ModificaTecnico />,
      },
    ],
  },
]);

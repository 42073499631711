class ArticoliService {
  async getAll(): Promise<"unknownError" | Articolo[]> {
    try {
      const res = await fetch("/api/articoli");
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async get(id: number): Promise<"notFound" | "unknownError" | Articolo> {
    try {
      const res = await fetch(`/api/articoli/${id}`);
      if (res.status === 404) {
        return "notFound";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async create(
    dto: CreateOrUpdateArticoloDto
  ): Promise<"unknownError" | "codiceDaneaAlreadyUsed" | Articolo> {
    try {
      const res = await fetch(`/api/articoli`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      if (res.status === 409) {
        return "codiceDaneaAlreadyUsed";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: CreateOrUpdateArticoloDto
  ): Promise<"unknownError" | "codiceDaneaAlreadyUsed" | Articolo> {
    try {
      const res = await fetch(`/api/articoli/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      if (res.status === 409) {
        return "codiceDaneaAlreadyUsed";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export type Articolo = {
  id: number;
  codiceDanea: string;
  codiceUdm: string;
  descrizione: string;
  prezzoListinoCentesimi: number;
  produttore: string | null;
  ultimaModifica: string;
  deleted: boolean;
};

export type CreateOrUpdateArticoloDto = {
  codiceDanea: string;
  descrizione: string;
  codiceUdm: string;
  prezzoListinoCentesimi: number;
  produttore: string | null;
  deleted: boolean;
};

export default new ArticoliService();

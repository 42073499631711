import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ClientePicker from "../ClientePicker";
import { DatePicker } from "@mui/x-date-pickers";
import { CreateOrUpdateIntervento } from "../../services/InterventiService";
import { useMakeFieldProps } from "../../hooks/useMakeFieldProps";
import { NumberIntField } from "../shared/numberFields";
import useInterventi from "../../hooks/useInterventi";
import useCredenzialiDiAccesso from "../../hooks/useCredenzialiDiAccess";

export type DettagliInterventoFormProps = {
  intervento: CreateOrUpdateIntervento | undefined;
  onInterventoChange: (i: CreateOrUpdateIntervento) => void;
  loading?: boolean;
};

export default function DettagliInterventoForm({
  intervento,
  onInterventoChange,
  loading = false,
}: DettagliInterventoFormProps) {
  const { credenzialiDiAccesso } = useCredenzialiDiAccesso();
  const { makeNumberFieldProps, makeDateFieldProps } = useMakeFieldProps(
    intervento,
    onInterventoChange,
    loading
  );

  const numeroSuccessivoIntervento = useNumeroSuccessivoIntervento();

  const autore = intervento?.autoreId
    ? credenzialiDiAccesso?.find((c) => intervento.autoreId === c.id)
    : undefined;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={2}>
              <NumberIntField
                label="Numero"
                {...makeNumberFieldProps("numero")}
              />
              {!intervento?.numero && (
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={
                    intervento &&
                    (() =>
                      onInterventoChange({
                        ...intervento,
                        numero: numeroSuccessivoIntervento,
                      }))
                  }
                >
                  Assegna numero {numeroSuccessivoIntervento}
                </Button>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ClientePicker {...makeNumberFieldProps("clienteId")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              sx={{ width: "100%" }}
              label="Inizio"
              {...makeDateFieldProps("inizio")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              sx={{ width: "100%" }}
              label="Fine"
              {...makeDateFieldProps("fine")}
            />
          </Grid>
          {autore && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                <Typography>Creato da: {autore.nomeUtente}</Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

function useNumeroSuccessivoIntervento() {
  const { interventi } = useInterventi();
  return Math.max(...(interventi || []).map((i) => i.numero || 0)) + 1;
}

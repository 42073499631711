import React from "react";
import dayjs, { Dayjs } from "dayjs";

export function useMakeFieldProps<T>(
  fields: T | undefined,
  onFieldsChange: (fields: T) => void,
  loading: boolean
) {
  return {
    makeTextFieldProps(fieldName: keyof T) {
      return {
        value: fields?.[fieldName] || "",
        disabled: loading || !fields,
        onChange: (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          if (!fields) {
            return;
          }
          onFieldsChange({
            ...fields,
            [fieldName]: e.target.value,
          });
        },
      };
    },
    makeNumberFieldProps(fieldName: keyof T) {
      return {
        value: (fields?.[fieldName] || 0) as number,
        disabled: loading || !fields,
        onChange: (value: number | null) => {
          if (!fields) {
            return;
          }
          onFieldsChange({
            ...fields,
            [fieldName]: value,
          });
        },
      };
    },
    makeDateFieldProps(fieldName: keyof T) {
      return {
        value: (fields?.[fieldName] || dayjs()) as Dayjs,
        disabled: loading || !fields,
        onChange: (value: Dayjs | null) => {
          if (!fields) {
            return;
          }
          onFieldsChange({
            ...fields,
            [fieldName]: value,
          });
        },
      };
    },
  };
}

import { useMutation, useQueryClient } from "@tanstack/react-query";
import InterventiService, { Intervento } from "../services/InterventiService";

export default function useSegnaInterventoComeVistoDaWeb() {
  const queryClient = useQueryClient();
  const { isLoading, mutate, error } = useMutation({
    mutationFn: async (intervento: Intervento) => {
      await InterventiService.segnaComeVistoDalWeb(intervento);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(["interventi"]);
    },
  });
  return {
    isLoading,
    segnaComeVistoDaWeb: mutate,
    error,
  };
}

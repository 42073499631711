import { Button, Stack } from "@mui/material";
import { ReactNode } from "react";

export type CenteredButtonProps = { children: ReactNode; onClick?: () => void };

export default function CenteredButton({
  children,
  onClick,
}: CenteredButtonProps) {
  return (
    <Stack direction="row" justifyContent="center">
      <Button variant="contained" size="small" onClick={onClick}>
        {children}
      </Button>
    </Stack>
  );
}

import { useQuery } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import TecniciService from "../services/TecniciService";

export default function useTecnici() {
  const {
    isLoading,
    error,
    data: tecnici,
  } = useQuery({
    queryKey: ["tecnici"],
    queryFn: unknownErrorToException(() => TecniciService.getAll()),
  });

  return { isLoading, error, tecnici };
}

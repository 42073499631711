import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { useMakeFieldProps } from "../hooks/useMakeFieldProps";
import { CreateOrUpdateClienteDto } from "../services/ClientiService";
import { NumberIntField } from "./shared/numberFields";

export type ClienteFormProps = {
  loading?: boolean;
  cliente: CreateOrUpdateClienteDto | undefined;
  onClienteChange: (a: CreateOrUpdateClienteDto) => void;
  codiceDaneaAlreadyUsed?: boolean;
};

export default function ClienteForm({
  loading = false,
  cliente,
  onClienteChange,
  codiceDaneaAlreadyUsed = false,
}: ClienteFormProps) {
  const { makeTextFieldProps, makeNumberFieldProps } = useMakeFieldProps(
    cliente,
    onClienteChange,
    loading
  );

  return (
    <Card style={{ position: "relative" }}>
      {loading && (
        <LinearProgress style={{ position: "absolute", left: 0, right: 0 }} />
      )}
      <CardContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Codice Danea"
                fullWidth
                size="small"
                {...makeTextFieldProps("codiceDanea")}
                error={codiceDaneaAlreadyUsed}
                helperText={
                  codiceDaneaAlreadyUsed &&
                  "Questo codice Danea è già assegnato ad un altro articolo"
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Denominazione"
                fullWidth
                size="small"
                {...makeTextFieldProps("denominazione")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Codice Fiscale"
                fullWidth
                size="small"
                {...makeTextFieldProps("codiceFiscale")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Partita IVA"
                fullWidth
                size="small"
                {...makeTextFieldProps("partitaIva")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Indirizzo"
                fullWidth
                size="small"
                {...makeTextFieldProps("indirizzo")}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberIntField label="CAP" {...makeNumberFieldProps("cap")} />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                variant="outlined"
                label="Città"
                fullWidth
                size="small"
                {...makeTextFieldProps("citta")}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

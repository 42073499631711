import { Alert, Button, Stack } from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import TecnicoForm from "../components/TecnicoForm";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import TecniciService, {
  CreateOrUpdateTecnicoDto,
} from "../services/TecniciService";
import useIdParamFromRouter from "../hooks/useIdParamFromRouter";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteButton from "../components/shared/DeleteButton";

export default function ModificaTecnico() {
  const id = useIdParamFromRouter();
  const {
    isLoading,
    error,
    data: tecnico,
  } = useQuery({
    queryKey: ["tecnici", id],
    queryFn: unknownErrorToException(() => TecniciService.get(id)),
  });

  const [modifiedTecnico, setModifiedTecnico] = useState<
    CreateOrUpdateTecnicoDto | undefined
  >();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<"unknownError" | undefined>();

  async function doUpdate(
    modifiedTecnico: CreateOrUpdateTecnicoDto | undefined
  ) {
    if (!modifiedTecnico) {
      return;
    }
    setUpdateError(undefined);
    setIsUpdating(true);
    const result = await TecniciService.update(id, modifiedTecnico);
    setIsUpdating(false);
    if (result === "unknownError") {
      setUpdateError(result);
      return;
    }

    await queryClient.invalidateQueries(["tecnici"]);
    navigate("/tecnici");
  }

  async function doDeleteTecnico() {
    if (!tecnico || tecnico === "notFound") {
      return;
    }
    await doUpdate({ ...tecnico, deleted: true });
  }

  return (
    <Stack>
      <TitleBar
        title="Modifica tecnico"
        actions={
          <>
            <DeleteButton
              confirmationQuestion={
                tecnico && tecnico !== "notFound"
                  ? `Sei sicuro di voler eliminare il tecnico "${tecnico.cognome} ${tecnico.nome}"`
                  : ""
              }
              onDeletionConfirmed={doDeleteTecnico}
            />

            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={
                isLoading ||
                isUpdating ||
                !modifiedTecnico ||
                modifiedTecnico.nome.trim().length === 0 ||
                modifiedTecnico.cognome.trim().length === 0
              }
              onClick={() => doUpdate(modifiedTecnico)}
            >
              Salva modifiche
            </Button>
          </>
        }
      />

      {!!error && (
        <Alert severity="error">
          Errore durante il caricamento del tecnico. Verifica la connessione a
          internet e prova ad aggiornare la pagina
        </Alert>
      )}

      {tecnico === "notFound" && (
        <Alert severity="warning">
          Impossibile trovare il tecnico con id {id}
        </Alert>
      )}

      {updateError === "unknownError" && (
        <Alert severity="warning">
          Impossibile salvare le modifiche del tecnico
        </Alert>
      )}

      {tecnico !== "notFound" && (
        <TecnicoForm
          loading={isLoading || isUpdating}
          tecnico={modifiedTecnico || tecnico}
          onTecnicoChange={setModifiedTecnico}
        />
      )}
    </Stack>
  );
}

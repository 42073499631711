import { Alert, Button, Stack } from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import ArticoloForm from "../components/ArticoloForm";
import React, { useState } from "react";
import ArticoliService, {
  CreateOrUpdateArticoloDto,
} from "../services/ArticoliService";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

export default function AggiungiArticolo() {
  const [articolo, setArticolo] = useState<CreateOrUpdateArticoloDto>({
    codiceDanea: "",
    descrizione: "",
    codiceUdm: "",
    prezzoListinoCentesimi: 100,
    produttore: "",
    deleted: false,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState<
    "unknownError" | "codiceDaneaAlreadyUsed" | undefined
  >();
  async function doCreate() {
    setIsCreating(true);
    const result = await ArticoliService.create(articolo);
    setIsCreating(false);
    if (result === "unknownError" || result === "codiceDaneaAlreadyUsed") {
      setCreateError(result);
      return;
    }

    await queryClient.invalidateQueries(["articoli"]);
    navigate("/articoli");
  }

  return (
    <Stack direction="column" spacing={2}>
      <TitleBar
        title="Aggiungi articolo"
        actions={
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={isCreating || articolo.codiceDanea.trim().length === 0}
            onClick={doCreate}
          >
            Aggiungi articolo
          </Button>
        }
      />

      {createError === "unknownError" && (
        <Alert severity="warning">Impossibile inserire l'articolo.</Alert>
      )}

      <ArticoloForm
        codiceDaneaAlreadyUsed={createError === "codiceDaneaAlreadyUsed"}
        articolo={articolo}
        onArticoloChange={setArticolo}
      />
    </Stack>
  );
}

class SessioneService {
  async createSessione(
    nomeUtente: string,
    password: string
  ): Promise<"unknownError" | "invalidNomeUtenteOrPassword" | SessioneDto> {
    try {
      const response = await fetch("/api/sessione", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ nomeUtente, password, isAdmin: true }),
      });
      if (response.status >= 500) {
        return "unknownError";
      } else if (response.status >= 400) {
        return "invalidNomeUtenteOrPassword";
      }
      return await response.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async getSession(): Promise<null | SessioneDto> {
    try {
      const response = await fetch("/api/sessione");
      if (response.status !== 200) {
        return null;
      }
      return await response.json();
    } catch (e) {
      return null;
    }
  }

  async logout(): Promise<boolean> {
    try {
      const response = await fetch("/api/sessione", {
        method: "DELETE",
      });
      return response.status === 204;
    } catch (e) {
      return false;
    }
  }
}

export type SessioneDto = {
  nomeUtente: string;
};

export default new SessioneService();

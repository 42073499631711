import { Alert, Button, Stack } from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import TecniciService, {
  CreateOrUpdateTecnicoDto,
} from "../services/TecniciService";
import TecnicoForm from "../components/TecnicoForm";

export default function AggiungiTecnico() {
  const [tecnico, setTecnico] = useState<CreateOrUpdateTecnicoDto>({
    nome: "",
    cognome: "",
    deleted: false,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState<"unknownError" | undefined>();
  async function doCreate() {
    setIsCreating(true);
    const result = await TecniciService.create(tecnico);
    setIsCreating(false);
    if (result === "unknownError") {
      setCreateError(result);
      return;
    }

    await queryClient.invalidateQueries(["tecnici"]);
    navigate("/tecnici");
  }

  return (
    <Stack direction="column" spacing={2}>
      <TitleBar
        title="Aggiungi tecnico"
        actions={
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={
              isCreating ||
              tecnico.nome.trim().length === 0 ||
              tecnico.cognome.trim().length === 0
            }
            onClick={doCreate}
          >
            Aggiungi tecnico
          </Button>
        }
      />

      {createError === "unknownError" && (
        <Alert severity="warning">Impossibile inserire il tecnico.</Alert>
      )}

      <TecnicoForm tecnico={tecnico} onTecnicoChange={setTecnico} />
    </Stack>
  );
}

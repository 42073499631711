class LavorazioniArticoliNonCatalogatiService {
  async getByInterventoId(
    interventoId: number
  ): Promise<"unknownError" | LavorazioneArticoloNonCatalogato[]> {
    try {
      const res = await fetch(
        `/api/lavorazioniArticoliNonCatalogati?interventoId=${interventoId}`
      );
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: UpdateLavorazioneArticoloNonCatalogato
  ): Promise<"unknownError" | LavorazioneArticoloNonCatalogato> {
    try {
      const res = await fetch(`/api/lavorazioniArticoliNonCatalogati/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export default new LavorazioniArticoliNonCatalogatiService();

export type LavorazioneArticoloNonCatalogato = {
  id: number;
  n: number | null;
  articoloNonCatalogatoId: number;
  lavorazioneId: number;
  deleted: boolean;
};

export type UpdateLavorazioneArticoloNonCatalogato = {
  n: number | null;
  deleted: boolean;
};

import { Autocomplete, TextField } from "@mui/material";
import useTecnici from "../hooks/useTecnici";

export type TecnicoPickerProps = {
  value: number | null;
  onChange: (v: number | null) => void;
  excludeTecniciId?: number[];
  disabled?: boolean;
};

export default function TecnicoPicker({
  value,
  onChange,
  excludeTecniciId = [],
  disabled = false,
}: TecnicoPickerProps) {
  const { isLoading, error, tecnici } = useTecnici();

  const availableOptions = (tecnici || []).map((t) => ({
    id: t.id,
    label: `${t.nome} ${t.cognome}`,
    deleted: t.deleted,
  }));
  return (
    <Autocomplete
      size="small"
      disabled={isLoading || !!error || disabled}
      value={availableOptions.find((t) => t.id === value) || null}
      onChange={(e, v) => onChange(v?.id || null)}
      renderInput={(params) => <TextField {...params} label="Tecnico" />}
      options={availableOptions.filter(
        (o) => !o.deleted && !excludeTecniciId?.includes(o.id)
      )}
      noOptionsText="Nessun tecnico"
    />
  );
}

import { NumericFormat } from "react-number-format";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";

export type NumberFieldProps = {
  value: number;
  onChange: (value: number) => void;
  label?: string;
  unitaDiMisura?: string | null;
};

export function NumberIntField({ value, onChange, label }: NumberFieldProps) {
  return (
    <NumericFormat
      value={value}
      onValueChange={(values) => {
        const { value } = values;
        if (value.trim() === "") {
          return;
        }
        onChange(parseInt(value));
      }}
      customInput={TextField}
      variant="outlined"
      label={label}
      fullWidth
      size="small"
      allowNegative={false}
    />
  );
}

export function NumberDoubleField({
  value,
  onChange,
  label,
  unitaDiMisura,
}: NumberFieldProps) {
  return (
    <NumericFormat
      decimalSeparator=","
      decimalScale={2}
      value={value}
      onValueChange={(values) => {
        const { value } = values;
        if (value.trim() === "") {
          return;
        }
        onChange(parseFloat(value));
      }}
      customInput={TextField}
      variant="outlined"
      label={label}
      fullWidth
      size="small"
      allowNegative={false}
      InputProps={
        unitaDiMisura
          ? {
              endAdornment: (
                <InputAdornment position="end">{unitaDiMisura}</InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
}

export function PrezzoInCentesimiField({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <NumericFormat
      value={value / 100}
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      onValueChange={(values) => {
        const { value } = values;
        if (value.trim() === "") {
          return;
        }
        onChange(Math.round(parseFloat(value) * 100));
      }}
      customInput={TextField}
      size="small"
      label="Prezzo"
      fullWidth
      variant="outlined"
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
    />
  );
}

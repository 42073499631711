import { useQuery } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import InterventiService from "../services/InterventiService";

export default function useInterventi() {
  const {
    isLoading,
    error,
    data: interventi,
  } = useQuery({
    queryKey: ["interventi"],
    queryFn: unknownErrorToException(() => InterventiService.getAll()),
  });
  return { isLoading, error, interventi };
}

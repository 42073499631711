import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

export type TableProps<T> = {
  rows: T[] | null;
  loading: boolean;
  columns: ColumnDef<T, any>[];
  defaultSortBy: keyof T;
  defaultSortByDesc?: boolean;
};

export default function AppTable<T>({
  rows,
  columns,
  loading,
  defaultSortBy,
  defaultSortByDesc = false,
}: TableProps<T>) {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: defaultSortBy as string,
      desc: defaultSortByDesc,
    },
  ]);

  const table = useReactTable<T>({
    data: rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <Paper>
      {loading && <LinearProgress style={{ marginTop: "-4px" }} />}
      <TableContainer>
        <Table size="small">
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    style={{ width: header.column.columnDef.size }}
                  >
                    <TableSortLabel
                      active={header.column.getIsSorted() !== false}
                      direction={header.column.getIsSorted() || undefined}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {rows !== null && (
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={rows?.length || 0}
        rowsPerPage={table.getState().pagination.pageSize}
        page={table.getState().pagination.pageIndex}
        onPageChange={(_, p) => table.setPageIndex(p)}
        onRowsPerPageChange={(s) => table.setPageSize(parseInt(s.target.value))}
        labelDisplayedRows={({ page, count }) =>
          `Pagina ${page + 1} di ${table.getPageCount()} (${count} righe)`
        }
        labelRowsPerPage="Righe per pagina"
      />
    </Paper>
  );
}

import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import ArticoliService, {
  Articolo,
  CreateOrUpdateArticoloDto,
} from "../services/ArticoliService";
import { createColumnHelper } from "@tanstack/react-table";
import AppTable from "../components/shared/AppTable";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TitleBar from "../components/shared/TitleBar";
import useArticoli from "../hooks/useArticoli";
import FilePickerButton from "../components/FilePickerButton";
import { useImportDialog } from "../components/ImportDialog";
import { parseImportArticoliFile } from "../services/ImportArticoliService";
import { prezzoInCentesimiToString } from "../formattingUtils";

export default function ListaArticoli() {
  const { isLoading, error, articoli, invalidateCache } = useArticoli();

  const [codiceDaneaFilter, setCodiceDaneaFilter] = useState("");
  const [descrizioneFilter, setDescrizioneFilter] = useState("");

  const filteredArticoli = useMemo(() => {
    if (articoli === null) {
      return null;
    }
    return articoli
      ?.filter((a) => !a.deleted)
      ?.filter(
        (a) =>
          a.codiceDanea
            .toLowerCase()
            .indexOf(codiceDaneaFilter.toLowerCase()) >= 0 &&
          a.descrizione
            .toLowerCase()
            .indexOf(descrizioneFilter.toLowerCase()) >= 0
      );
  }, [articoli, codiceDaneaFilter, descrizioneFilter]);

  const { dialog, openDialog } = useImportDialog<CreateOrUpdateArticoloDto>(
    {
      title: "Importa articoli da file Excel",
      itemSingularName: "articolo",
      itemPluralName: "articoli",
    },
    {
      parseFile: parseImportArticoliFile,
      isItemNew: (articolo) =>
        !articoli?.find((a) => a.codiceDanea === articolo.codiceDanea),
      createItem: async (articolo) => {
        const result = await ArticoliService.create(articolo);
        return result !== "unknownError" && result !== "codiceDaneaAlreadyUsed";
      },
      updateItem: async (articolo) => {
        const existingArticolo = articoli?.find(
          (a) => a.codiceDanea === articolo.codiceDanea
        );
        if (!existingArticolo) {
          return false;
        }
        const result = await ArticoliService.update(
          existingArticolo.id,
          articolo
        );
        return result !== "unknownError" && result !== "codiceDaneaAlreadyUsed";
      },
      invalidateCache,
    }
  );

  return (
    <Stack direction="column" spacing={2}>
      {dialog}
      <TitleBar
        title="Articoli"
        actions={
          <>
            <FilePickerButton onPickFile={openDialog} />
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/articoli/new"
            >
              Aggiungi
            </Button>
          </>
        }
      />

      <ArticoliFilter
        codiceDaneaFilter={codiceDaneaFilter}
        onCodiceDaneaFilterChange={setCodiceDaneaFilter}
        descrizioneFilter={descrizioneFilter}
        onDescrizioneFilterChange={setDescrizioneFilter}
      />

      {!!error && (
        <Alert severity="error">
          Errore durante il caricamento della lista degli articoli. Verifica la
          connessione a internet e prova ad aggiornare la pagina
        </Alert>
      )}

      <AppTable
        rows={filteredArticoli || null}
        loading={isLoading}
        columns={columns}
        defaultSortBy="descrizione"
      />
    </Stack>
  );
}

function ArticoliFilter({
  codiceDaneaFilter,
  onCodiceDaneaFilterChange,
  descrizioneFilter,
  onDescrizioneFilterChange,
}: {
  codiceDaneaFilter: string;
  onCodiceDaneaFilterChange: (f: string) => void;
  descrizioneFilter: string;
  onDescrizioneFilterChange: (f: string) => void;
}) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            Filtra per codice Danea
          </Grid>
          <Grid item xs={6} md={9}>
            <div>
              <TextField
                value={codiceDaneaFilter}
                onChange={(e) => onCodiceDaneaFilterChange(e.target.value)}
                size="small"
                placeholder="Codice Danea ..."
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            Filtra per Descrizione
          </Grid>
          <Grid item xs={6} md={9}>
            <div>
              <TextField
                value={descrizioneFilter}
                onChange={(e) => onDescrizioneFilterChange(e.target.value)}
                size="small"
                placeholder="Descrizione ..."
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const columnHelper = createColumnHelper<Articolo>();
const columns = [
  columnHelper.display({
    id: "actions",
    size: 50,
    cell: ({ row }) => (
      <IconButton
        size="small"
        component={Link}
        to={`/articoli/${row.original.id}`}
      >
        <OpenInNewIcon />
      </IconButton>
    ),
  }),
  columnHelper.accessor("descrizione", { size: 200, header: "Descrizione" }),
  columnHelper.accessor("codiceDanea", { size: 60, header: "Codice Danea" }),
  columnHelper.accessor("codiceUdm", { size: 50, header: "Unità di misura" }),
  columnHelper.accessor("prezzoListinoCentesimi", {
    size: 60,
    header: "Prezzo",
    cell: (props) => prezzoInCentesimiToString(props.getValue()),
  }),
  columnHelper.accessor("produttore", { size: 100, header: "Produttore" }),
];

import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import { Link } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import ClientePicker, { clienteToString } from "../components/ClientePicker";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import useInterventi from "../hooks/useInterventi";
import AppTable from "../components/shared/AppTable";
import { createColumnHelper, Row } from "@tanstack/react-table";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  InterventoWithTecniciIds,
  isInterventoModificatoDaTablet,
} from "../services/InterventiService";
import useClienti, { useClientiById } from "../hooks/useClienti";
import { Cliente } from "../services/ClientiService";
import useEliminaInterventi from "../hooks/useEliminaInterventi";
import { toast } from "react-toastify";
import InventoryIcon from "@mui/icons-material/Inventory";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { useSessionStorage } from "@uidotdev/usehooks";

export default function ListaInterventi() {
  const [clienteIdFilter, setClienteIdFilter] = useSessionStorage<
    number | null
  >("ListaInterventi_clienteIdFilter", null);
  // const [tecnicoIdFilter, setTecnicoIdFilter] = useState<number | null>(null);
  const [dateGteFilter, setDateGteFilter] = useSessionStorage<string | null>(
    "ListaInterventi_dateGteFilter",
    null
  );
  const [dateLteFilter, setDateLteFilter] = useSessionStorage<string | null>(
    "ListaInterventi_dateLteFilter",
    null
  );
  const [includeArchivedFilter, setIncludeArchivedFilter] = useState(false);

  const [selectedInterventiId, setSelectedInterventiId] = useState<number[]>(
    []
  );

  const { isLoading, error, interventi } = useInterventi();

  const filteredInterventi = useMemo(() => {
    if (!interventi) {
      return null;
    }
    return interventi
      .filter((i) => !i.deleted)
      .filter((i) => !i.archived || includeArchivedFilter)
      .filter((i) => {
        if (clienteIdFilter && i.clienteId !== clienteIdFilter) {
          return false;
        }
        // if (tecnicoIdFilter && !i.tecniciIds.includes(tecnicoIdFilter)) {
        //   return false;
        // }
        if (dateGteFilter) {
          if (i.inizio.isBefore(dateGteFilter)) {
            return i.fine ? i.fine.isAfter(dateGteFilter) : false;
          }
        }
        if (dateLteFilter) {
          if (i.inizio.isAfter(dateLteFilter)) {
            return false;
          }
        }
        return true;
      });
  }, [
    interventi,
    clienteIdFilter,
    // tecnicoIdFilter,
    dateGteFilter,
    dateLteFilter,
    includeArchivedFilter,
  ]);

  useEffect(() => {
    setSelectedInterventiId([]);
  }, [clienteIdFilter, dateGteFilter, dateLteFilter]);

  const { isLoading: eliminaInterventiLoading, eliminaInterventi } =
    useEliminaInterventi();
  function doEliminaInterventi() {
    if (eliminaInterventiLoading) {
      return;
    }
    if (
      !window.confirm(
        "Sei sicuro di voler eliminare definitivamente gli interventi selezionati?"
      )
    ) {
      return;
    }

    eliminaInterventi(selectedInterventiId, {
      onSuccess: () => {
        setSelectedInterventiId([]);
        toast.success("Interventi eliminati con successo!");
      },
      onError: () => {
        toast.error("Errore durante l'eliminazione degli interventi");
      },
    });
  }

  const clientiById = useClientiById();
  const columns = useMemo(
    () =>
      createColumns(
        clientiById,
        selectedInterventiId,
        setSelectedInterventiId,
        eliminaInterventiLoading
      ),
    [clientiById, selectedInterventiId, eliminaInterventiLoading]
  );

  return (
    <Stack direction="column" spacing={2}>
      <TitleBar
        title="Interventi"
        actions={
          <>
            {selectedInterventiId.length > 0 ? (
              <Button
                color="error"
                variant="contained"
                disabled={eliminaInterventiLoading}
                onClick={doEliminaInterventi}
              >
                Elimina {selectedInterventiId.length}{" "}
                {selectedInterventiId.length > 1 ? "interventi" : "intervento"}
              </Button>
            ) : null}

            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/interventi/new"
              disabled={eliminaInterventiLoading}
            >
              Aggiungi
            </Button>
          </>
        }
      />

      <InterventiFilter
        clienteId={clienteIdFilter}
        onClienteIdChange={setClienteIdFilter}
        // tecnicoId={tecnicoIdFilter}
        // onTecnicoIdChange={setTecnicoIdFilter}
        dateGte={dateGteFilter}
        onChangeDateGte={setDateGteFilter}
        dateLte={dateLteFilter}
        onChangeDateLte={setDateLteFilter}
        includeArchivedFilter={includeArchivedFilter}
        onChangeIncludeArchivedFilter={setIncludeArchivedFilter}
      />

      {!!error && (
        <Alert severity="error">
          Errore durante il caricamento della lista degli interventi. Verifica
          la connessione a internet e prova ad aggiornare la pagina
        </Alert>
      )}

      <AppTable
        rows={filteredInterventi || null}
        loading={isLoading}
        columns={columns}
        defaultSortBy="inizio"
        defaultSortByDesc
      />
    </Stack>
  );
}

function InterventiFilter({
  clienteId,
  onClienteIdChange,
  // tecnicoId,
  // onTecnicoIdChange,
  dateGte,
  onChangeDateGte,
  dateLte,
  onChangeDateLte,
  includeArchivedFilter,
  onChangeIncludeArchivedFilter,
}: {
  clienteId: number | null;
  onClienteIdChange: (id: number | null) => void;
  // tecnicoId: number | null;
  // onTecnicoIdChange: (id: number | null) => void;
  dateGte: string | null;
  onChangeDateGte: (d: string | null) => void;
  dateLte: string | null;
  onChangeDateLte: (d: string | null) => void;
  includeArchivedFilter: boolean;
  onChangeIncludeArchivedFilter: (includeArchivedFilter: boolean) => void;
}) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            Filtra per cliente
          </Grid>
          <Grid item xs={12} md={9}>
            <ClientePicker value={clienteId} onChange={onClienteIdChange} />
          </Grid>

          {/*<Grid item xs={12} md={3}>*/}
          {/*  Filtra per tecnico*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={9}>*/}
          {/*  <TecnicoPicker value={tecnicoId} onChange={onTecnicoIdChange} />*/}
          {/*</Grid>*/}

          <Grid item xs={12} md={3}>
            Filtra per data
          </Grid>
          <Grid item xs={12} md={9}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>Dal</Typography>
              <DatePicker
                value={dateGte ? dayjs(dateGte) : null}
                onChange={(d) => {
                  const date = d ? dayjs(d).startOf("day") : null;
                  onChangeDateGte(
                    date && date?.isValid() ? date.toISOString() : null
                  );
                }}
                maxDate={dateLte ? dayjs(dateLte) : undefined}
                format="DD/MM/YYYY"
                slotProps={{ textField: { size: "small" } }}
              />
              <Typography>Al</Typography>
              <DatePicker
                value={dateLte ? dayjs(dateLte) : null}
                onChange={(d) => {
                  const date = d ? dayjs(d).endOf("day") : null;
                  onChangeDateLte(
                    date && date?.isValid() ? date.toISOString() : null
                  );
                }}
                minDate={dateGte ? dayjs(dateGte) : undefined}
                format="DD/MM/YYYY"
                slotProps={{ textField: { size: "small" } }}
              />
            </Stack>
          </Grid>
          {/*<Grid*/}
          {/*  item*/}
          {/*  xs={12}*/}
          {/*  md={3}*/}
          {/*  sx={{ display: "flex", justifyContent: "flex-end" }}*/}
          {/*>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Switch*/}
          {/*        checked={includeArchivedFilter}*/}
          {/*        onChange={(e) => {*/}
          {/*          onChangeIncludeArchivedFilter(e.target.checked);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label="Includi archiviati"*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
      </CardContent>
    </Card>
  );
}

const columnHelper = createColumnHelper<InterventoWithTecniciIds>();
const createColumns = (
  clientiById: { [id: number]: Cliente },
  selectedInterventiId: number[],
  setSelectedInterventiId: React.Dispatch<React.SetStateAction<number[]>>,
  disabled: boolean
) => [
  columnHelper.accessor("numero", {
    size: 100,
    header: "Numero",
    cell: ({ row, getValue }) => (
      <Stack direction="row" alignItems="center" sx={{ minWidth: 130 }}>
        <Typography sx={{ minWidth: "2.5em", mr: 1 }}>{getValue()}</Typography>
        <IconButton
          disabled={disabled}
          size="small"
          component={Link}
          to={`/interventi/${row.original.id}`}
        >
          <OpenInNewIcon />
        </IconButton>
        {row.original.archived ? (
          <Tooltip title="Intervento archiviato">
            <InventoryIcon sx={{ color: "gray" }} />
          </Tooltip>
        ) : null}
        {isInterventoModificatoDaTablet(row.original) ? (
          <Tooltip
            title={
              row.original.ultimaVisualizzazioneDaWeb
                ? "Intervento modificato da tablet"
                : "Nuovo intervento"
            }
          >
            <NewReleasesIcon color="primary" />
          </Tooltip>
        ) : null}
      </Stack>
    ),
  }),
  columnHelper.accessor("clienteId", {
    header: "Cliente",
    cell: ClienteCell,
    size: 400,
    sortingFn: (rowA, rowB) => {
      const clienteAId = rowA.getValue<number>("clienteId");
      const clienteBId = rowB.getValue<number>("clienteId");
      return (clientiById[clienteAId]?.denominazione || "").localeCompare(
        clientiById[clienteBId]?.denominazione || ""
      );
    },
  }),
  columnHelper.accessor("inizio", {
    header: "Data inizio",
    size: 100,
    cell: ({ getValue }) => getValue()?.format("DD/MM/YYYY"),
  }),
  columnHelper.display({
    id: "actions",
    size: 30,
    cell: ({ row }) => (
      <>
        <Checkbox
          disabled={disabled}
          checked={selectedInterventiId.includes(row.original.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedInterventiId((selected) => [
                ...selected,
                row.original.id,
              ]);
            } else {
              setSelectedInterventiId((selected) =>
                selected.filter((id) => id !== row.original.id)
              );
            }
          }}
        />
      </>
    ),
  }),
];

function ClienteCell({ row }: { row: Row<InterventoWithTecniciIds> }) {
  const { isLoading, error, clienti } = useClienti();
  if (isLoading) {
    return <Typography>Caricamento ...</Typography>;
  }
  const cliente = (clienti || []).find((c) => c.id === row.original.clienteId);
  if (!!error || !cliente) {
    return <Typography>Errore</Typography>;
  }
  return <Typography>{clienteToString(cliente)}</Typography>;
}

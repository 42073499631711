import { useQuery } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import CredenzialiDiAccessoService from "../services/CredenzialiDiAccessoService";

export default function useCredenzialiDiAccesso() {
  const {
    isLoading,
    error,
    data: credenzialiDiAccesso,
  } = useQuery({
    queryKey: ["credenzialiDiAccesso"],
    queryFn: unknownErrorToException(() =>
      CredenzialiDiAccessoService.getAll()
    ),
  });
  return {
    isLoading,
    error,
    credenzialiDiAccesso,
  };
}

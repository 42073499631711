import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useMakeFieldProps } from "../../hooks/useMakeFieldProps";
import {
  LavorazioneArticoloFormModel,
  LavorazioneFormModel,
  LavorazioneTecnicoFormModel,
  makeArrayUpdater,
} from "./models";
import RemoveIcon from "@mui/icons-material/Remove";
import CenteredButton from "../CenteredButton";
import LocalIdService from "../../services/LocalIdService";
import TecnicoPicker from "../TecnicoPicker";
import { NumberDoubleField } from "../shared/numberFields";
import ArticoloPicker from "../ArticoloPicker";
import { LavorazioneArticoloNonCatalogato } from "../../services/LavorazioniArticoliNonCatalogatiService";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import useArticoliNonCatalogati from "../../hooks/useArticoliNonCatalogati";
import { useCatalogaArticoloDialog } from "../CatalogaArticoloDialog";
import { ArticoloNonCatalogato } from "../../services/ArticoliNonCatalogatiService";
import useArticoli from "../../hooks/useArticoli";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export type LavorazioneFormProps = {
  lavorazione: LavorazioneFormModel;
  onLavorazioneChange: (l: LavorazioneFormModel) => void;
  lavorazioneTecnici?: LavorazioneTecnicoFormModel[];
  onLavorazioneTecniciChange: (l: LavorazioneTecnicoFormModel[]) => void;
  lavorazioneArticoli?: LavorazioneArticoloFormModel[];
  onLavorazioneArticoliChange: (l: LavorazioneArticoloFormModel[]) => void;
  lavorazioneArticoliNonCatalogati?: LavorazioneArticoloNonCatalogato[];
  onLavorazioneArticoliNonCatalogatiChange?: (
    l: LavorazioneArticoloNonCatalogato[]
  ) => void;
  loading?: boolean;
  canBeDeleted?: boolean;
};

export default function LavorazioneForm({
  lavorazione,
  onLavorazioneChange,
  lavorazioneTecnici,
  onLavorazioneTecniciChange,
  lavorazioneArticoli,
  onLavorazioneArticoliChange,
  lavorazioneArticoliNonCatalogati,
  onLavorazioneArticoliNonCatalogatiChange,

  loading = false,
  canBeDeleted = false,
}: LavorazioneFormProps) {
  const { makeTextFieldProps } = useMakeFieldProps(
    lavorazione,
    onLavorazioneChange,
    loading
  );
  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={6}>
              <TextField
                variant="outlined"
                label="Nome"
                fullWidth
                size="small"
                {...makeTextFieldProps("nome")}
              />
            </Grid>
            {canBeDeleted && (
              <Grid item xs={4} sm={6}>
                <Stack direction="row" justifyContent="end">
                  <IconButton
                    color="error"
                    title="Elimina lavorazione"
                    onClick={() =>
                      onLavorazioneChange({ ...lavorazione, deleted: true })
                    }
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Stack>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Note"
                fullWidth
                size="small"
                multiline
                rows={3}
                {...makeTextFieldProps("note")}
              />
            </Grid>
          </Grid>

          {lavorazioneTecnici && (
            <TecniciTable
              lavorazioneTecnici={lavorazioneTecnici}
              onLavorazioneTecniciChange={onLavorazioneTecniciChange}
              localLavorazioneId={lavorazione.localId}
            />
          )}
          <Divider />
          {lavorazioneArticoli && (
            <ArticoliTable
              lavorazioneArticoli={lavorazioneArticoli}
              onLavorazioneArticoliChange={onLavorazioneArticoliChange}
              localLavorazioneId={lavorazione.localId}
            />
          )}
          <Divider />
          {lavorazioneArticoliNonCatalogati &&
            lavorazioneArticoliNonCatalogati.filter((l) => !l.deleted).length >
              0 &&
            onLavorazioneArticoliNonCatalogatiChange && (
              <ArticoliNonCatalogatiTable
                lavorazioneArticoliNonCatalogati={
                  lavorazioneArticoliNonCatalogati
                }
                onLavorazioneArticoliNonCatalogatiChange={
                  onLavorazioneArticoliNonCatalogatiChange
                }
                onCreateLavorazioneArticolo={(articoloId, n) => {
                  onLavorazioneArticoliChange([
                    ...(lavorazioneArticoli || []),
                    {
                      localId: LocalIdService.nextId(),
                      articoloId,
                      n,
                      localLavorazioneId: lavorazione.localId,
                      deleted: false,
                    },
                  ]);
                }}
              />
            )}
        </Stack>
      </CardContent>
    </Card>
  );
}

function TecniciTable({
  lavorazioneTecnici,
  onLavorazioneTecniciChange,
  localLavorazioneId,
}: {
  lavorazioneTecnici: LavorazioneTecnicoFormModel[];
  onLavorazioneTecniciChange: (l: LavorazioneTecnicoFormModel[]) => void;
  localLavorazioneId: number;
}) {
  const lavorazioneTecniciNotDeleted = lavorazioneTecnici.filter(
    (l) => !l.deleted
  );
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Tecnici
      </Typography>

      <CenteredButton
        onClick={() =>
          onLavorazioneTecniciChange([
            ...lavorazioneTecnici,
            {
              localId: LocalIdService.nextId(),
              tecnicoId: null,
              oreDiLavoro: 1,
              localLavorazioneId,
              deleted: false,
            },
          ])
        }
      >
        Aggiungi tecnico
      </CenteredButton>

      {lavorazioneTecniciNotDeleted.length > 0 && (
        <Card variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Tecnico</TableCell>
                <TableCell style={{ width: "200px" }}>Ore lavorate</TableCell>
                <TableCell style={{ width: "100px" }}>Rimuovi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lavorazioneTecniciNotDeleted.map((lt) => (
                <TecnicoRow
                  key={lt.localId}
                  lavorazioneTecnico={lt}
                  onLavorazioneTecnicoChange={makeArrayUpdater(
                    lavorazioneTecnici,
                    onLavorazioneTecniciChange
                  )}
                  lavorazioneTecniciNotDeleted={lavorazioneTecniciNotDeleted}
                />
              ))}
            </TableBody>
          </Table>
        </Card>
      )}
    </>
  );
}

function TecnicoRow({
  lavorazioneTecnico,
  onLavorazioneTecnicoChange,
  lavorazioneTecniciNotDeleted,
}: {
  lavorazioneTecnico: LavorazioneTecnicoFormModel;
  onLavorazioneTecnicoChange: (lt: LavorazioneTecnicoFormModel) => void;
  lavorazioneTecniciNotDeleted: LavorazioneTecnicoFormModel[];
}) {
  const { makeNumberFieldProps } = useMakeFieldProps(
    lavorazioneTecnico,
    onLavorazioneTecnicoChange,
    false
  );
  return (
    <TableRow>
      <TableCell>
        <TecnicoPicker
          {...makeNumberFieldProps("tecnicoId")}
          disabled={!!lavorazioneTecnico.serverId}
          excludeTecniciId={lavorazioneTecniciNotDeleted.map(
            (lt) => lt.tecnicoId || 0
          )}
        />
      </TableCell>
      <TableCell>
        <NumberDoubleField {...makeNumberFieldProps("oreDiLavoro")} />
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() =>
            onLavorazioneTecnicoChange({ ...lavorazioneTecnico, deleted: true })
          }
        >
          <RemoveIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function ArticoliTable({
  lavorazioneArticoli,
  onLavorazioneArticoliChange,
  localLavorazioneId,
}: {
  lavorazioneArticoli: LavorazioneArticoloFormModel[];
  onLavorazioneArticoliChange: (l: LavorazioneArticoloFormModel[]) => void;
  localLavorazioneId: number;
}) {
  const lavorazioneArticoliNotDeleted = lavorazioneArticoli.filter(
    (l) => !l.deleted
  );
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Articoli
      </Typography>

      <CenteredButton
        onClick={() =>
          onLavorazioneArticoliChange([
            ...lavorazioneArticoli,
            {
              localId: LocalIdService.nextId(),
              articoloId: null,
              n: 1,
              localLavorazioneId,
              deleted: false,
            },
          ])
        }
      >
        Aggiungi articolo
      </CenteredButton>

      {lavorazioneArticoliNotDeleted.length > 0 && (
        <Card variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Articolo</TableCell>
                <TableCell style={{ width: "200px" }}>Quantità</TableCell>
                <TableCell style={{ width: "100px" }}>Rimuovi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lavorazioneArticoliNotDeleted.map((la) => (
                <ArticoloRow
                  key={la.localId}
                  lavorazioneArticolo={la}
                  lavorazioneArticoliNotDeleted={lavorazioneArticoliNotDeleted}
                  onLavorazioneArticoloChange={makeArrayUpdater(
                    lavorazioneArticoli,
                    onLavorazioneArticoliChange
                  )}
                />
              ))}
            </TableBody>
          </Table>
        </Card>
      )}
    </>
  );
}

function ArticoloRow({
  lavorazioneArticolo,
  onLavorazioneArticoloChange,
  lavorazioneArticoliNotDeleted,
}: {
  lavorazioneArticolo: LavorazioneArticoloFormModel;
  onLavorazioneArticoloChange: (la: LavorazioneArticoloFormModel) => void;
  lavorazioneArticoliNotDeleted: LavorazioneArticoloFormModel[];
}) {
  const { makeNumberFieldProps } = useMakeFieldProps(
    lavorazioneArticolo,
    onLavorazioneArticoloChange,
    false
  );
  const { articoli } = useArticoli();
  const articolo = articoli?.find(
    (a) => a.id === lavorazioneArticolo.articoloId
  );
  return (
    <TableRow>
      <TableCell>
        <ArticoloPicker
          {...makeNumberFieldProps("articoloId")}
          disabled={!!lavorazioneArticolo.serverId}
          excludeArticoliId={lavorazioneArticoliNotDeleted.map(
            (la) => la.articoloId || 0
          )}
        />
      </TableCell>
      <TableCell>
        <NumberDoubleField
          {...makeNumberFieldProps("n")}
          unitaDiMisura={articolo?.codiceUdm}
        />
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() =>
            onLavorazioneArticoloChange({
              ...lavorazioneArticolo,
              deleted: true,
            })
          }
        >
          <RemoveIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function ArticoliNonCatalogatiTable({
  lavorazioneArticoliNonCatalogati,
  onLavorazioneArticoliNonCatalogatiChange,
  onCreateLavorazioneArticolo,
}: {
  lavorazioneArticoliNonCatalogati: LavorazioneArticoloNonCatalogato[];
  onLavorazioneArticoliNonCatalogatiChange: (
    l: LavorazioneArticoloNonCatalogato[]
  ) => void;
  onCreateLavorazioneArticolo: (articoloId: number, n: number) => void;
}) {
  const lavorazioneArticoliNonCatalogatiNotDeleted =
    lavorazioneArticoliNonCatalogati.filter((l) => !l.deleted);

  const { dialog, openDialog } = useCatalogaArticoloDialog(
    (articoloNonCatalogatoId: number, articoloId: number) => {
      const lanc = lavorazioneArticoliNonCatalogatiNotDeleted.find(
        (l) => l.articoloNonCatalogatoId === articoloNonCatalogatoId
      );
      if (!lanc) {
        return;
      }

      onLavorazioneArticoliNonCatalogatiChange(
        lavorazioneArticoliNonCatalogati.map((l) => {
          if (l.id !== lanc.id) {
            return l;
          } else {
            return { ...l, deleted: true };
          }
        })
      );

      onCreateLavorazioneArticolo(articoloId, lanc.n || 1);
    }
  );

  return (
    <>
      {dialog}
      <Typography variant="subtitle1" gutterBottom>
        Articoli non catalogati
      </Typography>

      <Card variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "200px" }}>Descrizione</TableCell>
              <TableCell style={{ width: "100px" }}>Codice Danea</TableCell>
              <TableCell style={{ width: "300px" }}>Immagine</TableCell>
              <TableCell style={{ width: "100px" }}>Quantità</TableCell>
              <TableCell style={{ width: "50px" }}>Cataloga</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lavorazioneArticoliNonCatalogatiNotDeleted.map((lanc) => (
              <ArticoliNonCatalogatoRow
                key={lanc.id}
                lavorazioneArticoloNonCatalogato={lanc}
                openCatalogaArticoloDialog={openDialog}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  );
}

function ArticoliNonCatalogatoRow({
  lavorazioneArticoloNonCatalogato,
  openCatalogaArticoloDialog,
}: {
  lavorazioneArticoloNonCatalogato: LavorazioneArticoloNonCatalogato;
  openCatalogaArticoloDialog: (
    articoloNonCatalogato: ArticoloNonCatalogato
  ) => void;
}) {
  const { articoliNonCatalogati } = useArticoliNonCatalogati();
  const articoloNonCatalogato = articoliNonCatalogati?.find(
    (a) => a.id === lavorazioneArticoloNonCatalogato.articoloNonCatalogatoId
  );

  return (
    <TableRow>
      <TableCell>{articoloNonCatalogato?.descrizione}</TableCell>
      <TableCell>{articoloNonCatalogato?.codiceDanea}</TableCell>
      <TableCell style={{ maxWidth: 500 }}>
        <Stack gap={2} direction="row" style={{ overflowY: "auto" }}>
          {articoloNonCatalogato?.immaginiIds?.map((id) => (
            <img
              key={id}
              width="200px"
              alt="Errore durante il caricamento dell'immagine"
              src={`/api/immagini/${id}`}
            />
          ))}
        </Stack>
      </TableCell>
      <TableCell>{lavorazioneArticoloNonCatalogato.n}</TableCell>
      <TableCell>
        <IconButton
          disabled={!articoloNonCatalogato}
          onClick={
            articoloNonCatalogato &&
            (() => openCatalogaArticoloDialog(articoloNonCatalogato))
          }
        >
          <MenuBookIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

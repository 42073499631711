import SessioneService, { SessioneDto } from "../services/SessioneService";
import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../images/logo.png";

export type LoginPageProps = {
  onLoggedIn: (sessione: SessioneDto) => void;
};

export default function LoginPage({ onLoggedIn }: LoginPageProps) {
  const [nomeUtente, setNomeUtente] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  async function handleSubmit() {
    setLoading(true);
    setError(null);
    const result = await SessioneService.createSessione(nomeUtente, password);
    setLoading(false);
    if (result === "unknownError") {
      setError(
        "Si è verificato un errore sconosciuto, verifica la connessione a internet"
      );
    } else if (result === "invalidNomeUtenteOrPassword") {
      setError("Nome utente o password non validi");
    } else {
      onLoggedIn(result);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{
          marginTop: 8,
        }}
      >
        <img src={logo} width="192px" alt="Ghidini Fabio" />
        <Typography component="h1" variant="h5">
          Accedi
        </Typography>

        {error && (
          <Alert sx={{ width: "100%" }} severity="warning">
            {error}
          </Alert>
        )}

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit().then();
          }}
          noValidate
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="Nome utente"
            autoComplete="email"
            autoFocus
            variant="outlined"
            value={nomeUtente}
            onChange={(e) => setNomeUtente(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={
              nomeUtente.length === 0 || password.length === 0 || loading
            }
          >
            Accedi
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

import { useEffect, useState } from "react";

export type ImportState<T> =
  | { name: "idle" }
  | { name: "readingFile" }
  | { name: "readError"; missingHeader?: string }
  | { name: "readSuccess"; items: T[] }
  | { name: "importing" }
  | { name: "importSuccess" }
  | { name: "importError" };

export type ImportDelegate<T> = {
  parseFile: (file: File) => Promise<T[] | { missingHeader: string } | false>;
  isItemNew: (item: T) => boolean;
  createItem: (item: T) => Promise<boolean>;
  updateItem: (item: T) => Promise<boolean>;
  invalidateCache: () => Promise<void>;
};

export function useImportDelegate<T>(
  delegate: ImportDelegate<T>,
  file: File | undefined
) {
  const [state, setState] = useState<ImportState<T>>({ name: "idle" });

  useEffect(() => {
    if (!file) {
      setState({ name: "idle" });
      return;
    }
    if (state.name !== "idle") {
      return;
    }

    delegate.parseFile(file).then((parseResult) => {
      if (parseResult === false || "missingHeader" in parseResult) {
        setState({
          name: "readError",
          missingHeader:
            parseResult !== false ? parseResult.missingHeader : undefined,
        });
        return;
      }

      setState({ name: "readSuccess", items: parseResult });
    });
  }, [file, delegate, state.name]);

  async function doImport(items: T[]) {
    setState({ name: "importing" });
    for (let item of items) {
      const importResult = delegate.isItemNew(item)
        ? await delegate.createItem(item)
        : await delegate.updateItem(item);
      if (!importResult) {
        setState({ name: "importError" });
        await delegate.invalidateCache();
        return;
      }
    }
    await delegate.invalidateCache();
    setState({ name: "importSuccess" });
  }

  return {
    state,
    doImport,
  };
}

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import ClientiService from "../services/ClientiService";
import { useMemo } from "react";

export default function useClienti() {
  const {
    isLoading,
    error,
    data: clienti,
  } = useQuery({
    queryKey: ["clienti"],
    queryFn: unknownErrorToException(() => ClientiService.getAll()),
  });
  const queryClient = useQueryClient();
  return {
    isLoading,
    error,
    clienti,
    invalidateCache: () => queryClient.invalidateQueries(["clienti"]),
  };
}

export function useClientiById() {
  const { clienti } = useClienti();
  return useMemo(
    () => (clienti ? Object.fromEntries(clienti.map((c) => [c.id, c])) : {}),
    [clienti]
  );
}

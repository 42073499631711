import { Alert, Button, Stack } from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import ArticoloForm from "../components/ArticoloForm";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import ArticoliService, {
  CreateOrUpdateArticoloDto,
} from "../services/ArticoliService";
import useIdParamFromRouter from "../hooks/useIdParamFromRouter";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteButton from "../components/shared/DeleteButton";
import dayjs from "dayjs";

export default function ModificaArticolo() {
  const id = useIdParamFromRouter();
  const {
    isLoading,
    error,
    data: articolo,
  } = useQuery({
    queryKey: ["articoli", id],
    queryFn: unknownErrorToException(() => ArticoliService.get(id)),
  });

  const [modifiedArticolo, setModifiedArticolo] = useState<
    CreateOrUpdateArticoloDto | undefined
  >();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<
    "unknownError" | "codiceDaneaAlreadyUsed" | undefined
  >();
  async function doUpdate(
    modifiedArticolo: CreateOrUpdateArticoloDto | undefined
  ) {
    if (!modifiedArticolo) {
      return;
    }
    setUpdateError(undefined);
    setIsUpdating(true);
    const result = await ArticoliService.update(id, modifiedArticolo);
    setIsUpdating(false);
    if (result === "unknownError" || result === "codiceDaneaAlreadyUsed") {
      setUpdateError(result);
      return;
    }

    await queryClient.invalidateQueries(["articoli"]);
    navigate("/articoli");
  }

  async function doDelete() {
    if (!articolo || articolo === "notFound") {
      return;
    }
    await doUpdate({
      ...articolo,
      codiceDanea: `${articolo.codiceDanea} (Eliminato il ${dayjs().format(
        "DD/MM/YYYY HH:mm:ss"
      )})`,
      deleted: true,
    });
  }

  return (
    <Stack>
      <TitleBar
        title="Modifica articolo"
        actions={
          <>
            <DeleteButton
              confirmationQuestion={
                articolo && articolo !== "notFound"
                  ? `Sei sicuro di voler eliminare l'articolo con codice Danea "${articolo.codiceDanea}"?`
                  : ""
              }
              onDeletionConfirmed={doDelete}
            />

            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={
                isLoading ||
                isUpdating ||
                !modifiedArticolo ||
                modifiedArticolo.codiceDanea.trim().length === 0
              }
              onClick={() => doUpdate(modifiedArticolo)}
            >
              Salva modifiche
            </Button>
          </>
        }
      />

      {!!error && (
        <Alert severity="error">
          Errore durante il caricamento dell'articolo. Verifica la connessione a
          internet e prova ad aggiornare la pagina
        </Alert>
      )}

      {articolo === "notFound" && (
        <Alert severity="warning">
          Impossibile trovare l'articolo con id {id}
        </Alert>
      )}

      {updateError === "unknownError" && (
        <Alert severity="warning">
          Impossibile salvare le modifiche dell'articolo
        </Alert>
      )}

      {articolo !== "notFound" && (
        <ArticoloForm
          codiceDaneaAlreadyUsed={updateError === "codiceDaneaAlreadyUsed"}
          loading={isLoading || isUpdating}
          articolo={modifiedArticolo || articolo}
          onArticoloChange={setModifiedArticolo}
        />
      )}
    </Stack>
  );
}

import { Alert, Button, Stack } from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import ClientiService, {
  CreateOrUpdateClienteDto,
} from "../services/ClientiService";
import ClienteForm from "../components/ClienteForm";

export default function AggiungiCliente() {
  const [cliente, setCliente] = useState<CreateOrUpdateClienteDto>({
    codiceDanea: "",
    denominazione: "",
    codiceFiscale: "",
    partitaIva: "",
    indirizzo: "",
    cap: null,
    citta: "",
    deleted: false,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState<
    "unknownError" | "codiceDaneaAlreadyUsed" | undefined
  >();
  async function doCreate() {
    setIsCreating(true);
    const result = await ClientiService.create(cliente);
    setIsCreating(false);
    if (result === "unknownError" || result === "codiceDaneaAlreadyUsed") {
      setCreateError(result);
      return;
    }

    await queryClient.invalidateQueries(["clienti"]);
    navigate("/clienti");
  }

  return (
    <Stack direction="column" spacing={2}>
      <TitleBar
        title="Aggiungi cliente"
        actions={
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={isCreating || cliente.denominazione.trim().length === 0}
            onClick={doCreate}
          >
            Aggiungi cliente
          </Button>
        }
      />

      {createError === "unknownError" && (
        <Alert severity="warning">Impossibile inserire il cliente.</Alert>
      )}

      <ClienteForm
        codiceDaneaAlreadyUsed={createError === "codiceDaneaAlreadyUsed"}
        cliente={cliente}
        onClienteChange={setCliente}
      />
    </Stack>
  );
}

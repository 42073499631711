import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import ClientiService, {
  Cliente,
  CreateOrUpdateClienteDto,
} from "../services/ClientiService";
import { createColumnHelper } from "@tanstack/react-table";
import AppTable from "../components/shared/AppTable";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TitleBar from "../components/shared/TitleBar";
import useClienti from "../hooks/useClienti";
import FilePickerButton from "../components/FilePickerButton";
import { useImportDialog } from "../components/ImportDialog";
import { parseImportClientiFile } from "../services/ImportClientiService";

export default function ListaClienti() {
  const { isLoading, error, clienti, invalidateCache } = useClienti();

  const [codiceDaneaFilter, setCodiceDaneaFilter] = useState("");
  const [denominazioneFilter, setDenominazioneFilter] = useState("");

  const filteredClienti = useMemo(() => {
    if (clienti === null) {
      return null;
    }
    return clienti
      ?.filter((c) => !c.deleted)
      ?.filter(
        (a) =>
          (a.codiceDanea || "")
            .toLowerCase()
            .indexOf(codiceDaneaFilter.toLowerCase()) >= 0 &&
          a.denominazione
            .toLowerCase()
            .indexOf(denominazioneFilter.toLowerCase()) >= 0
      );
  }, [clienti, codiceDaneaFilter, denominazioneFilter]);

  const { dialog, openDialog } = useImportDialog<CreateOrUpdateClienteDto>(
    {
      title: "Importa clienti da file Excel",
      itemSingularName: "cliente",
      itemPluralName: "clienti",
    },
    {
      parseFile: parseImportClientiFile,
      isItemNew: (cliente) =>
        !clienti?.find((c) => c.codiceDanea === cliente.codiceDanea),
      createItem: async (cliente) => {
        const result = await ClientiService.create(cliente);
        return result !== "unknownError" && result !== "codiceDaneaAlreadyUsed";
      },
      updateItem: async (cliente) => {
        const existingCliente = clienti?.find(
          (c) => c.codiceDanea === cliente.codiceDanea
        );
        if (!existingCliente) {
          return false;
        }
        const result = await ClientiService.update(existingCliente.id, cliente);
        return result !== "unknownError" && result !== "codiceDaneaAlreadyUsed";
      },
      invalidateCache,
    }
  );

  return (
    <Stack direction="column" spacing={2}>
      {dialog}
      <TitleBar
        title="Clienti"
        actions={
          <>
            <FilePickerButton onPickFile={openDialog} />
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/clienti/new"
            >
              Aggiungi
            </Button>
          </>
        }
      />

      <ClientiFilter
        codiceDaneaFilter={codiceDaneaFilter}
        onCodiceDaneaFilterChange={setCodiceDaneaFilter}
        denominazioneFilter={denominazioneFilter}
        onDenominazioneFilterChange={setDenominazioneFilter}
      />

      {!!error && (
        <Alert severity="error">
          Errore durante il caricamento della lista degli clienti. Verifica la
          connessione a internet e prova ad aggiornare la pagina
        </Alert>
      )}

      <AppTable
        rows={filteredClienti || null}
        loading={isLoading}
        columns={columns}
        defaultSortBy="denominazione"
      />
    </Stack>
  );
}

function ClientiFilter({
  codiceDaneaFilter,
  onCodiceDaneaFilterChange,
  denominazioneFilter,
  onDenominazioneFilterChange,
}: {
  codiceDaneaFilter: string;
  onCodiceDaneaFilterChange: (f: string) => void;
  denominazioneFilter: string;
  onDenominazioneFilterChange: (f: string) => void;
}) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            Filtra per codice Danea
          </Grid>
          <Grid item xs={6} md={9}>
            <div>
              <TextField
                value={codiceDaneaFilter}
                onChange={(e) => onCodiceDaneaFilterChange(e.target.value)}
                size="small"
                placeholder="Codice Danea ..."
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            Filtra per Denominazione
          </Grid>
          <Grid item xs={6} md={9}>
            <div>
              <TextField
                value={denominazioneFilter}
                onChange={(e) => onDenominazioneFilterChange(e.target.value)}
                size="small"
                placeholder="Denominazione ..."
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const columnHelper = createColumnHelper<Cliente>();
const columns = [
  columnHelper.display({
    id: "actions",
    size: 50,
    cell: ({ row }) => (
      <IconButton
        size="small"
        component={Link}
        to={`/clienti/${row.original.id}`}
      >
        <OpenInNewIcon />
      </IconButton>
    ),
  }),
  columnHelper.accessor("denominazione", { header: "Denominazione" }),
  columnHelper.accessor("codiceDanea", { size: 50, header: "Codice Danea" }),
  columnHelper.accessor("codiceFiscale", { header: "Codice Fiscale" }),
  columnHelper.accessor("partitaIva", { header: "Partita IVA" }),
  columnHelper.accessor("indirizzo", { header: "Indirizzo" }),
  columnHelper.accessor("cap", { size: 50, header: "CAP" }),
  columnHelper.accessor("citta", { header: "Città" }),
];

import { Alert, Button, Stack, Typography } from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import React, { useState } from "react";
import InterventoForm from "../components/intervento/InterventoForm";
import useIdParamFromRouter from "../hooks/useIdParamFromRouter";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import InterventiService, {
  CreateOrUpdateIntervento,
  isInterventoModificatoDaTablet,
} from "../services/InterventiService";
import LavorazioniService, {
  Lavorazione,
} from "../services/LavorazioniService";
import {
  LavorazioneArticoloFormModel,
  LavorazioneFormModel,
  LavorazioneTecnicoFormModel,
} from "../components/intervento/models";
import LavorazioniTecniciService, {
  LavorazioneTecnico,
} from "../services/LavorazioniTecniciService";
import LavorazioniArticoliService, {
  LavorazioneArticolo,
} from "../services/LavorazioniArticoliService";
import LavorazioniArticoliNonCatalogatiService, {
  LavorazioneArticoloNonCatalogato,
} from "../services/LavorazioniArticoliNonCatalogatiService";
import SyncService from "../services/SyncService";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import useArticoli from "../hooks/useArticoli";
import useTecnici from "../hooks/useTecnici";
import useClienti from "../hooks/useClienti";
import useArticoliNonCatalogati from "../hooks/useArticoliNonCatalogati";
import DeleteButton from "../components/shared/DeleteButton";
import { getInterventoTitleFromClienti } from "../modelUtils";
import InventoryIcon from "@mui/icons-material/Inventory";
import useArchiviaInterventi from "../hooks/useArchiviaInterventi";
import { toast } from "react-toastify";
import useSegnaInterventoComeVistoDaWeb from "../hooks/useSegnaInterventoComeVistoDaWeb";

export default function ModificaIntervento() {
  const id = useIdParamFromRouter();
  const {
    loading,
    error,
    intervento,
    lavorazioni,
    lavorazioniTecnici,
    lavorazioniArticoli,
    lavorazioniArticoliNonCatalogati,
    invalidateCache,
  } = useLoadIntervento(id);

  const [modifiedIntervento, setModifiedIntervento] = useState<
    CreateOrUpdateIntervento | undefined
  >();
  const [modifiedLavorazioni, setModifiedLavorazioni] = useState<
    LavorazioneFormModel[] | undefined
  >();
  const [modifiedLavorazioniTecnici, setModifiedLavorazioniTecnici] = useState<
    LavorazioneTecnicoFormModel[] | undefined
  >();
  const [modifiedLavorazioniArticoli, setModifiedLavorazioniArticoli] =
    useState<LavorazioneArticoloFormModel[] | undefined>();
  const [
    modifiedLavorazioniArticoliNonCatalogati,
    setModifiedLavorazioniArticoliNonCatalogati,
  ] = useState<LavorazioneArticoloNonCatalogato[] | undefined>();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<
    "unknownError" | "numeroNonUnico" | undefined
  >();
  async function doUpdate(actionAfterUpdate: "close" | "refresh") {
    if (!intervento || intervento === "notFound") {
      return;
    }

    setUpdateError(undefined);
    setUpdating(true);
    const syncResult = await SyncService.sync(
      intervento,
      lavorazioni || [],
      lavorazioniTecnici || [],
      lavorazioniArticoli || [],
      lavorazioniArticoliNonCatalogati || [],

      modifiedIntervento || intervento,
      modifiedLavorazioni || lavorazioni || [],
      modifiedLavorazioniTecnici || lavorazioniTecnici || [],
      modifiedLavorazioniArticoli || lavorazioniArticoli || [],
      modifiedLavorazioniArticoliNonCatalogati ||
        lavorazioniArticoliNonCatalogati ||
        []
    );
    await invalidateCache();

    if (syncResult !== true) {
      setUpdateError(syncResult);
      setUpdating(false);
    } else if (actionAfterUpdate === "refresh") {
      setModifiedIntervento(undefined);
      setModifiedLavorazioni(undefined);
      setModifiedLavorazioniTecnici(undefined);
      setModifiedLavorazioniArticoli(undefined);
      setModifiedLavorazioniArticoliNonCatalogati(undefined);
      setUpdating(false);
    } else if (actionAfterUpdate === "close") {
      setUpdating(false);
      navigate("/interventi");
    }
  }

  const hasChanges =
    !!modifiedIntervento ||
    !!modifiedLavorazioni ||
    !!modifiedLavorazioniTecnici ||
    !!modifiedLavorazioniArticoli ||
    !!modifiedLavorazioniArticoliNonCatalogati;

  const { tecnici, isLoading: isTecniciLoading } = useTecnici();
  const { articoli, isLoading: isArticoliLoading } = useArticoli();
  const { clienti, isLoading: isClientiLoading } = useClienti();
  const { articoliNonCatalogati, isLoading: isArticoliNonCatalogatiLoading } =
    useArticoliNonCatalogati();

  const [isExporting, setIsExporting] = useState(false);

  async function doDelete() {
    if (!intervento || intervento === "notFound") {
      return;
    }

    setUpdateError(undefined);
    setUpdating(true);
    const result = await InterventiService.update(intervento.id, {
      ...intervento,
      numero: null,
      deleted: true,
    });
    if (result === "unknownError" || result === "numeroNonUnico") {
      setUpdateError("unknownError");
      setUpdating(false);
    } else {
      queryClient.invalidateQueries(["interventi"]).finally(() => {
        setUpdating(false);
        navigate("/interventi");
      });
    }
  }

  const { isLoading: archiviaInterventiLoading, archiviaInterventi } =
    useArchiviaInterventi();
  function doArchivia() {
    if (archiviaInterventiLoading || !intervento || intervento === "notFound") {
      return;
    }
    if (!window.confirm("Sei sicuro di voler archiviare l'intervento?")) {
      return;
    }

    archiviaInterventi([intervento.id], {
      onSuccess: () => {
        toast.success("Intervento archiviato con successo!");
        navigate("/interventi");
      },
      onError: () => {
        toast.error("Errore durante l'archiviazione dell'intervento");
      },
    });
  }

  const { isLoading: segnaComeVistoDaWebLoading, segnaComeVistoDaWeb } =
    useSegnaInterventoComeVistoDaWeb();
  function doSegnaComeVistoDaWeb() {
    if (
      segnaComeVistoDaWebLoading ||
      !intervento ||
      intervento === "notFound"
    ) {
      return;
    }

    segnaComeVistoDaWeb(intervento, {
      onError: () => {
        toast.error("Errore durante la modifica dell'intervento");
      },
    });
  }

  return (
    <Stack direction="column" gap={1}>
      <TitleBar
        title="Dettagli intervento"
        actions={
          <>
            {intervento &&
              intervento !== "notFound" &&
              !intervento.archived && (
                <Button
                  size="small"
                  disabled={
                    loading ||
                    updating ||
                    hasChanges ||
                    archiviaInterventiLoading
                  }
                  color="secondary"
                  variant="contained"
                  startIcon={<InventoryIcon />}
                  onClick={doArchivia}
                >
                  Archivia
                </Button>
              )}

            <DeleteButton
              size="small"
              confirmationQuestion={
                clienti && intervento && intervento !== "notFound"
                  ? `Sei sicuro di volere eliminare l'intervento "${getInterventoTitleFromClienti(
                      intervento,
                      clienti
                    )}"?`
                  : ""
              }
              onDeletionConfirmed={doDelete}
            />

            {intervento &&
              intervento !== "notFound" &&
              lavorazioni &&
              lavorazioniTecnici &&
              lavorazioniArticoli &&
              lavorazioniArticoliNonCatalogati && (
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled={
                    hasChanges ||
                    isExporting ||
                    isTecniciLoading ||
                    isArticoliLoading ||
                    isClientiLoading ||
                    isArticoliNonCatalogatiLoading
                  }
                  onClick={async () => {
                    setIsExporting(true);
                    const ExportInterventoExcelService =
                      await importExportInterventoExcelService();
                    await ExportInterventoExcelService.export(
                      intervento,
                      lavorazioni,
                      lavorazioniTecnici,
                      lavorazioniArticoli,
                      lavorazioniArticoliNonCatalogati,
                      tecnici || [],
                      articoli || [],
                      clienti || [],
                      articoliNonCatalogati || []
                    );
                    setIsExporting(false);
                  }}
                >
                  Esporta Excel
                </Button>
              )}
            {intervento &&
              intervento !== "notFound" &&
              lavorazioni &&
              lavorazioniTecnici &&
              lavorazioniArticoli &&
              lavorazioniArticoliNonCatalogati && (
                <Button
                  size="small"
                  startIcon={<PictureAsPdfIcon />}
                  variant="outlined"
                  color="secondary"
                  disabled={
                    hasChanges ||
                    isExporting ||
                    isTecniciLoading ||
                    isArticoliLoading ||
                    isClientiLoading
                  }
                  onClick={async () => {
                    setIsExporting(true);
                    const ExportInterventoPdfService =
                      await importExportInterventoPdfService();
                    const success = ExportInterventoPdfService.export(
                      intervento,
                      lavorazioni,
                      lavorazioniTecnici,
                      lavorazioniArticoli,
                      lavorazioniArticoliNonCatalogati,
                      tecnici || [],
                      articoli || [],
                      clienti || [],
                      articoliNonCatalogati || []
                    );
                    if (!success) {
                      alert(
                        "Errore durante la generazione del PDF. Prova ad aggiornare la pagina."
                      );
                    }
                    setIsExporting(false);
                  }}
                >
                  Esporta PDF
                </Button>
              )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={
                loading || updating || archiviaInterventiLoading || !hasChanges
              }
              onClick={() => doUpdate("refresh")}
            >
              Salva modifiche
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={
                loading || updating || archiviaInterventiLoading || !hasChanges
              }
              onClick={() => doUpdate("close")}
            >
              Salva modifiche e chiudi
            </Button>
          </>
        }
      />

      {error && (
        <Alert severity="error">
          Errore durante il caricamento dell'intervento. Verifica la connessione
          a internet e prova ad aggiornare la pagina.
        </Alert>
      )}

      {intervento === "notFound" && (
        <Alert severity="warning">
          Impossibile trovare l'intervento con id {id}.
        </Alert>
      )}

      {updateError === "unknownError" && (
        <Alert severity="warning">
          Error durante il salvataggio dell'intervento, verifica la connessione
          a internet o prova ad aggiornare la pagina.
        </Alert>
      )}

      {updateError === "numeroNonUnico" && (
        <Alert severity="warning">
          Esiste già un intervento con il numero{" "}
          {intervento !== "notFound" &&
            (modifiedIntervento || intervento || { numero: 0 }).numero}
          .
        </Alert>
      )}

      {intervento !== "notFound" && intervento?.archived && (
        <Alert severity="info">Questo intervento è stato archiviato.</Alert>
      )}

      {intervento &&
        intervento !== "notFound" &&
        isInterventoModificatoDaTablet(intervento) && (
          <Alert
            severity={
              intervento?.ultimaVisualizzazioneDaWeb ? "warning" : "info"
            }
            sx={{ "&>.MuiAlert-message": { width: "100%" } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography>
                {intervento?.ultimaVisualizzazioneDaWeb
                  ? "Questo intervento è stato modificato da un tablet."
                  : "Questo è un nuovo intervento."}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                disabled={segnaComeVistoDaWebLoading}
                onClick={doSegnaComeVistoDaWeb}
              >
                Nascondi notifica
              </Button>
            </Stack>
          </Alert>
        )}

      {intervento !== "notFound" && !error && (
        <InterventoForm
          loading={loading || updating}
          intervento={modifiedIntervento || intervento}
          onInterventoChange={setModifiedIntervento}
          lavorazioni={modifiedLavorazioni || lavorazioni}
          onLavorazioniChange={setModifiedLavorazioni}
          lavorazioniTecnici={modifiedLavorazioniTecnici || lavorazioniTecnici}
          onLavorazioniTecniciChange={setModifiedLavorazioniTecnici}
          lavorazioniArticoli={
            modifiedLavorazioniArticoli || lavorazioniArticoli
          }
          onLavorazioniArticoliChange={setModifiedLavorazioniArticoli}
          lavorazioniArticoliNonCatalogati={
            modifiedLavorazioniArticoliNonCatalogati ||
            lavorazioniArticoliNonCatalogati
          }
          onLavorazioniArticoliNonCatalogatiChange={
            setModifiedLavorazioniArticoliNonCatalogati
          }
        />
      )}
    </Stack>
  );
}

function useLoadIntervento(interventoId: number) {
  const queryClient = useQueryClient();
  const { isLoading, error, data } = useQuery({
    queryKey: ["interventi", interventoId],
    queryFn: unknownErrorToException(() => InterventiService.get(interventoId)),
  });

  const {
    isLoading: isLoadingLavorazioni,
    error: errorLavorazioni,
    data: lavorazioni,
  } = useQuery({
    queryKey: ["lavorazioni", { interventoId }],
    queryFn: unknownErrorToException(() =>
      LavorazioniService.getByInterventoId(interventoId)
    ),
  });

  const {
    isLoading: isLoadingLavorazioniTecnici,
    error: errorLavorazioniTecnici,
    data: lavorazioniTecnici,
  } = useQuery({
    queryKey: ["lavorazioniTecnici", { interventoId }],
    queryFn: unknownErrorToException(() =>
      LavorazioniTecniciService.getByInterventoId(interventoId)
    ),
  });

  const {
    isLoading: isLoadingLavorazioniArticoli,
    error: errorLavorazioniArticoli,
    data: lavorazioniArticoli,
  } = useQuery({
    queryKey: ["lavorazioniArticoli", { interventoId }],
    queryFn: unknownErrorToException(() =>
      LavorazioniArticoliService.getByInterventoId(interventoId)
    ),
  });

  const {
    isLoading: isLoadingLavorazioniArticoliNonCatalogati,
    error: errorLavorazioniArticoliNonCatalogati,
    data: lavorazioniArticoliNonCatalogati,
  } = useQuery({
    queryKey: ["lavorazioniArticoliNonCatalogati", { interventoId }],
    queryFn: unknownErrorToException(() =>
      LavorazioniArticoliNonCatalogatiService.getByInterventoId(interventoId)
    ),
  });

  const globalIsLoading =
    isLoading ||
    isLoadingLavorazioni ||
    isLoadingLavorazioniTecnici ||
    isLoadingLavorazioniArticoli ||
    isLoadingLavorazioniArticoliNonCatalogati;
  const globalError =
    !!error ||
    !!errorLavorazioni ||
    !!errorLavorazioniTecnici ||
    !!errorLavorazioniArticoli ||
    !!errorLavorazioniArticoliNonCatalogati;

  return {
    loading: globalIsLoading,
    error: globalError,
    intervento: data,
    lavorazioni: toLavorazioniFormModelArray(lavorazioni),
    lavorazioniTecnici: toLavorazioniTecniciFormModelArray(lavorazioniTecnici),
    lavorazioniArticoli:
      toLavorazioniArticoliFormModelArray(lavorazioniArticoli),
    lavorazioniArticoliNonCatalogati,
    invalidateCache: async () => {
      await Promise.all([
        queryClient.invalidateQueries(["interventi"]),
        queryClient.invalidateQueries(["lavorazioni", { interventoId }]),
        queryClient.invalidateQueries(["lavorazioniTecnici", { interventoId }]),
        queryClient.invalidateQueries([
          "lavorazioniArticoli",
          { interventoId },
        ]),
        queryClient.invalidateQueries([
          "lavorazioniArticoliNonCatalogati",
          { interventoId },
        ]),
      ]);
    },
  };
}

function toLavorazioniFormModelArray(lavorazioni: Lavorazione[] | undefined) {
  return lavorazioni?.map((l) => ({
    ...l,
    localId: l.id,
    serverId: l.id,
  }));
}

function toLavorazioniTecniciFormModelArray(
  lavorazioniTecnici: LavorazioneTecnico[] | undefined
) {
  return lavorazioniTecnici?.map((l) => ({
    ...l,
    localId: l.id,
    serverId: l.id,
    localLavorazioneId: l.lavorazioneId,
  }));
}

function toLavorazioniArticoliFormModelArray(
  lavorazioniTecnici: LavorazioneArticolo[] | undefined
) {
  return lavorazioniTecnici?.map((l) => ({
    ...l,
    localId: l.id,
    serverId: l.id,
    localLavorazioneId: l.lavorazioneId,
  }));
}

async function importExportInterventoPdfService() {
  return (await import("../services/ExportInterventoPdfService")).default;
}

async function importExportInterventoExcelService() {
  return (await import("../services/ExportInterventoExcelService")).default;
}

importExportInterventoPdfService().then(() =>
  console.log("ExportInterventoPdfService loaded")
);

importExportInterventoExcelService().then(() =>
  console.log("ExportInterventoExcelService loaded")
);

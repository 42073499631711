import { Autocomplete, TextField } from "@mui/material";
import useArticoli from "../hooks/useArticoli";

export type ArticoloPickerProps = {
  value: number | null;
  onChange: (v: number | null) => void;
  excludeArticoliId?: number[];
  disabled?: boolean;
  fullWidth?: boolean;
};

export default function ArticoloPicker({
  value,
  onChange,
  excludeArticoliId = [],
  disabled = false,
  fullWidth = false,
}: ArticoloPickerProps) {
  const { isLoading, error, articoli } = useArticoli();
  const availableOptions = (articoli || []).map((a) => ({
    id: a.id,
    label: `${a.codiceDanea} - ${a.descrizione}`,
    deleted: a.deleted,
  }));
  return (
    <Autocomplete
      fullWidth={fullWidth}
      size="small"
      disabled={isLoading || !!error || disabled}
      value={availableOptions.find((t) => t.id === value) || null}
      onChange={(e, v) => onChange(v?.id || null)}
      renderInput={(params) => <TextField {...params} label="Articolo" />}
      options={availableOptions.filter(
        (o) => !o.deleted && !excludeArticoliId?.includes(o.id)
      )}
      noOptionsText="Nessun articolo"
    />
  );
}

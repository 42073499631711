class TecniciService {
  async getAll(): Promise<"unknownError" | Tecnico[]> {
    try {
      const res = await fetch("/api/tecnici");
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async get(id: number): Promise<"notFound" | "unknownError" | Tecnico> {
    try {
      const res = await fetch(`/api/tecnici/${id}`);
      if (res.status === 404) {
        return "notFound";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async create(
    dto: CreateOrUpdateTecnicoDto
  ): Promise<"unknownError" | Tecnico> {
    try {
      const res = await fetch(`/api/tecnici`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: CreateOrUpdateTecnicoDto
  ): Promise<"unknownError" | Tecnico> {
    try {
      const res = await fetch(`/api/tecnici/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export type Tecnico = {
  id: number;
  nome: string;
  cognome: string;
  deleted: boolean;
};

export type CreateOrUpdateTecnicoDto = {
  nome: string;
  cognome: string;
  deleted: boolean;
};

export default new TecniciService();

class LavorazioniService {
  async getByInterventoId(
    interventoId: number
  ): Promise<"unknownError" | Lavorazione[]> {
    try {
      const res = await fetch(`/api/lavorazioni?interventoId=${interventoId}`);
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async create(
    dto: CreateOrUpdateLavorazione
  ): Promise<"unknownError" | Lavorazione> {
    try {
      const res = await fetch(`/api/lavorazioni`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: CreateOrUpdateLavorazione
  ): Promise<"unknownError" | Lavorazione> {
    try {
      const res = await fetch(`/api/lavorazioni/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export default new LavorazioniService();

export type Lavorazione = {
  id: number;
  interventoId: number;
  nome: string | null;
  note: string | null;
  deleted: boolean;
};

export type CreateOrUpdateLavorazione = {
  interventoId: number;
  nome: string | null;
  note: string | null;
  deleted: boolean | null;
};

import React, { useEffect, useState } from "react";
import { ArticoloNonCatalogato } from "../services/ArticoliNonCatalogatiService";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import ArticoloPicker from "./ArticoloPicker";
import ArticoloForm from "./ArticoloForm";
import ArticoliService, {
  CreateOrUpdateArticoloDto,
} from "../services/ArticoliService";

type CatalogaArticoloDialogProps = {
  articoloNonCatalogato?: ArticoloNonCatalogato;
  onArticoloCatalogato: (
    articoloNonCatalogatoId: number,
    articoloId: number
  ) => void;
  onClose: () => void;
};

function CatalogaArticoloDialog({
  articoloNonCatalogato,
  onArticoloCatalogato,
  onClose,
}: CatalogaArticoloDialogProps) {
  const [catalogaAction, setCatalogaAction] = useState<"nuovo" | "esistente">(
    "nuovo"
  );

  const { nuovoDialogContent, nuovoConfirmButton } =
    useNuovoCatalogaActionDialogContent(articoloNonCatalogato, (articoloId) => {
      onArticoloCatalogato(articoloNonCatalogato!.id, articoloId);
      onClose();
    });

  const { esistenteDialogContent, esistenteConfirmButton } =
    useEsistenteCatalogaActionDialogContent((articoloId) => {
      onArticoloCatalogato(articoloNonCatalogato!.id, articoloId);
      onClose();
    });

  return (
    <Dialog open={!!articoloNonCatalogato} maxWidth="md" fullWidth>
      <DialogTitle>Cataloga Articolo</DialogTitle>

      {articoloNonCatalogato && (
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <ArticoloNonCatalogatoInfo
              articoloNonCatalogato={articoloNonCatalogato}
            />

            <Divider />

            <CatalogaActionSelector
              value={catalogaAction}
              onChange={setCatalogaAction}
            />

            {catalogaAction === "nuovo"
              ? nuovoDialogContent
              : esistenteDialogContent}
          </Stack>
        </DialogContent>
      )}

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annulla
        </Button>
        {catalogaAction === "nuovo"
          ? nuovoConfirmButton
          : esistenteConfirmButton}
      </DialogActions>
    </Dialog>
  );
}

function ArticoloNonCatalogatoInfo({
  articoloNonCatalogato,
}: {
  articoloNonCatalogato: ArticoloNonCatalogato;
}) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Stack gap={2} direction="row" style={{ overflowY: "auto" }}>
          {articoloNonCatalogato?.immaginiIds?.map((id) => (
            <img
              key={id}
              width="200px"
              alt="Errore durante il caricamento dell'immagine"
              src={`/api/immagini/${id}`}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item sm={6}>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Codice Danea:</strong>
              </td>
              <td>{articoloNonCatalogato.codiceDanea}</td>
            </tr>

            <tr>
              <td>
                <strong>Descrizione:</strong>
              </td>
              <td>{articoloNonCatalogato.descrizione}</td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}

function CatalogaActionSelector({
  value,
  onChange,
}: {
  value: "nuovo" | "esistente";
  onChange: (value: "nuovo" | "esistente") => void;
}) {
  return (
    <FormControl>
      <FormLabel>Cataloga</FormLabel>
      <RadioGroup
        row
        value={value}
        onChange={(e) => onChange(e.target.value as "nuovo" | "esistente")}
      >
        <FormControlLabel
          value="nuovo"
          control={<Radio />}
          label="Creando un nuovo articolo"
        />
        <FormControlLabel
          value="esistente"
          control={<Radio />}
          label="Selezionandone uno esistente"
        />
      </RadioGroup>
    </FormControl>
  );
}

function useNuovoCatalogaActionDialogContent(
  articoloNonCatalogato: ArticoloNonCatalogato | undefined,
  onArticolo: (articoloId: number) => void
) {
  const [articolo, setArticolo] = useState<CreateOrUpdateArticoloDto>({
    codiceDanea: articoloNonCatalogato?.codiceDanea ?? "",
    descrizione: articoloNonCatalogato?.descrizione ?? "",
    codiceUdm: "",
    prezzoListinoCentesimi: 100,
    produttore: "",
    deleted: false,
  });
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState<
    "unknownError" | "codiceDaneaAlreadyUsed" | undefined
  >();

  useEffect(() => {
    if (articoloNonCatalogato) {
      setArticolo((a) => ({
        ...a,
        codiceDanea: articoloNonCatalogato.codiceDanea ?? "",
        descrizione: articoloNonCatalogato.descrizione ?? "",
      }));
    }
  }, [articoloNonCatalogato]);

  async function doCreate() {
    setIsCreating(true);
    setCreateError(undefined);
    const result = await ArticoliService.create(articolo);
    setIsCreating(false);
    if (result === "unknownError" || result === "codiceDaneaAlreadyUsed") {
      setCreateError(result);
      return;
    }

    onArticolo(result.id);
    setArticolo({
      codiceDanea: "",
      descrizione: "",
      codiceUdm: "",
      prezzoListinoCentesimi: 100,
      produttore: "",
      deleted: false,
    });
  }

  return {
    nuovoDialogContent: (
      <Stack direction="column" spacing={2}>
        {createError === "unknownError" && (
          <Alert severity="warning">Impossibile inserire l'articolo.</Alert>
        )}

        <ArticoloForm
          codiceDaneaAlreadyUsed={createError === "codiceDaneaAlreadyUsed"}
          articolo={articolo}
          onArticoloChange={setArticolo}
        />
      </Stack>
    ),
    nuovoConfirmButton: (
      <Button variant="contained" disabled={isCreating} onClick={doCreate}>
        Cataloga
      </Button>
    ),
  };
}

function useEsistenteCatalogaActionDialogContent(
  onArticolo: (articoloId: number) => void
) {
  const [articoloId, setArticoloId] = useState<number | null>(null);

  return {
    esistenteDialogContent: (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography style={{ flexShrink: 0 }}>
          Seleziona articolo esistente:
        </Typography>
        <ArticoloPicker fullWidth value={articoloId} onChange={setArticoloId} />
      </Stack>
    ),
    esistenteConfirmButton: (
      <Button
        variant="contained"
        disabled={articoloId === null}
        onClick={() => onArticolo(articoloId!!)}
      >
        Cataloga
      </Button>
    ),
  };
}

export function useCatalogaArticoloDialog(
  onArticoloCatalogato: (
    articoloNonCatalogatoId: number,
    articoloId: number
  ) => void
) {
  const [articoloNonCatalogato, setArticoloNonCatalogato] = useState<
    ArticoloNonCatalogato | undefined
  >();
  return {
    openDialog: (articoloNonCatalogato: ArticoloNonCatalogato) => {
      setArticoloNonCatalogato(articoloNonCatalogato);
    },
    dialog: (
      <CatalogaArticoloDialog
        onClose={() => setArticoloNonCatalogato(undefined)}
        articoloNonCatalogato={articoloNonCatalogato}
        onArticoloCatalogato={onArticoloCatalogato}
      />
    ),
  };
}

import { Alert, Button, Stack } from "@mui/material";
import TitleBar from "../components/shared/TitleBar";
import ClienteForm from "../components/ClienteForm";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import ClientiService, {
  CreateOrUpdateClienteDto,
} from "../services/ClientiService";
import useIdParamFromRouter from "../hooks/useIdParamFromRouter";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteButton from "../components/shared/DeleteButton";
import dayjs from "dayjs";

export default function ModificaCliente() {
  const id = useIdParamFromRouter();
  const {
    isLoading,
    error,
    data: cliente,
  } = useQuery({
    queryKey: ["clienti", id],
    queryFn: unknownErrorToException(() => ClientiService.get(id)),
  });

  const [modifiedCliente, setModifiedCliente] = useState<
    CreateOrUpdateClienteDto | undefined
  >();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<
    "unknownError" | "codiceDaneaAlreadyUsed" | undefined
  >();
  async function doUpdate(
    modifiedCliente: CreateOrUpdateClienteDto | undefined
  ) {
    if (!modifiedCliente) {
      return;
    }
    setUpdateError(undefined);
    setIsUpdating(true);
    const result = await ClientiService.update(id, modifiedCliente);
    setIsUpdating(false);
    if (result === "unknownError" || result === "codiceDaneaAlreadyUsed") {
      setUpdateError(result);
      return;
    }

    await queryClient.invalidateQueries(["clienti"]);
    navigate("/clienti");
  }

  async function doDelete() {
    if (!cliente || cliente === "notFound") {
      return;
    }
    await doUpdate({
      ...cliente,
      codiceDanea: `${cliente.codiceDanea} (Eliminato il ${dayjs().format(
        "DD/MM/YYYY HH:mm:ss"
      )})`,
      deleted: true,
    });
  }

  return (
    <Stack>
      <TitleBar
        title="Modifica cliente"
        actions={
          <>
            <DeleteButton
              confirmationQuestion={
                cliente && cliente !== "notFound"
                  ? `Sei sicuro di volere eliminare il cliente "${cliente.denominazione}"?`
                  : ""
              }
              onDeletionConfirmed={doDelete}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={
                isLoading ||
                isUpdating ||
                !modifiedCliente ||
                modifiedCliente.denominazione.trim().length === 0
              }
              onClick={() => doUpdate(modifiedCliente)}
            >
              Salva modifiche
            </Button>
          </>
        }
      />

      {!!error && (
        <Alert severity="error">
          Errore durante il caricamento del cliente. Verifica la connessione a
          internet e prova ad aggiornare la pagina
        </Alert>
      )}

      {cliente === "notFound" && (
        <Alert severity="warning">
          Impossibile trovare il cliente con id {id}
        </Alert>
      )}

      {updateError === "unknownError" && (
        <Alert severity="warning">
          Impossibile salvare le modifiche del cliente
        </Alert>
      )}

      {cliente !== "notFound" && (
        <ClienteForm
          codiceDaneaAlreadyUsed={updateError === "codiceDaneaAlreadyUsed"}
          loading={isLoading || isUpdating}
          cliente={modifiedCliente || cliente}
          onClienteChange={setModifiedCliente}
        />
      )}
    </Stack>
  );
}

import { CreateOrUpdateLavorazioneArticoloDto } from "../../services/LavorazioniArticoliService";
import { CreateOrUpdateLavorazioneTecnicoDto } from "../../services/LavorazioniTecniciService";
import { CreateOrUpdateLavorazione } from "../../services/LavorazioniService";

export type LavorazioneFormModel = Omit<
  CreateOrUpdateLavorazione,
  "interventoId"
> & {
  localId: number;
  serverId?: number;
};

export type LavorazioneTecnicoFormModel = Omit<
  CreateOrUpdateLavorazioneTecnicoDto,
  "lavorazioneId"
> & {
  localId: number;
  serverId?: number;
  localLavorazioneId: number;
};

export type LavorazioneArticoloFormModel = Omit<
  CreateOrUpdateLavorazioneArticoloDto,
  "lavorazioneId"
> & {
  localId: number;
  serverId?: number;
  localLavorazioneId: number;
};

export function makeArrayUpdater<T extends { localId: number }>(
  array: T[],
  onArrayChange: (updatedArray: T[]) => void
) {
  return function (updatedItem: T) {
    onArrayChange(
      array.map((item) =>
        item.localId === updatedItem.localId ? updatedItem : item
      )
    );
  };
}

export function makeFilteredArrayUpdater<T>(
  array: T[] | undefined,
  onArrayChange: (updatedArray: T[]) => void,
  filterKey: keyof T,
  filterValue: T[typeof filterKey]
) {
  return function (updatedFilteredArray: T[]) {
    if (!array) {
      return;
    }
    onArrayChange([
      ...array.filter((item) => item[filterKey] !== filterValue),
      ...updatedFilteredArray,
    ]);
  };
}

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import ArticoliService from "../services/ArticoliService";

export default function useArticoli() {
  const {
    isLoading,
    error,
    data: articoli,
  } = useQuery({
    queryKey: ["articoli"],
    queryFn: unknownErrorToException(() => ArticoliService.getAll()),
  });
  const queryClient = useQueryClient();
  return {
    isLoading,
    error,
    articoli,
    invalidateCache: () => queryClient.invalidateQueries(["articoli"]),
  };
}

import { Alert, Button, IconButton, Stack } from "@mui/material";
import { Tecnico } from "../services/TecniciService";
import AppTable from "../components/shared/AppTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TitleBar from "../components/shared/TitleBar";
import useTecnici from "../hooks/useTecnici";

export default function ListaTecnici() {
  const { isLoading, error, tecnici } = useTecnici();

  return (
    <Stack direction="column" spacing={2}>
      <TitleBar
        title="Tecnici"
        actions={
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/tecnici/new"
          >
            Aggiungi
          </Button>
        }
      />

      {!!error && (
        <Alert severity="error">
          Errore durante il caricamento della lista dei tecnici. Verifica la
          connessione a internet e prova ad aggiornare la pagina
        </Alert>
      )}

      <AppTable
        rows={tecnici?.filter((t) => !t.deleted) || null}
        loading={isLoading}
        columns={columns}
        defaultSortBy="nome"
      />
    </Stack>
  );
}

const columnHelper = createColumnHelper<Tecnico>();
const columns = [
  columnHelper.display({
    id: "actions",
    size: 50,
    cell: ({ row }) => (
      <IconButton
        size="small"
        component={Link}
        to={`/tecnici/${row.original.id}`}
      >
        <OpenInNewIcon />
      </IconButton>
    ),
  }),
  columnHelper.accessor("nome", { size: 200, header: "Nome" }),
  columnHelper.accessor("cognome", { size: 200, header: "Cognome" }),
];

import { Dayjs } from "dayjs";

export function unknownErrorToException<T>(
  f: () => Promise<"unknownError" | T>
): () => Promise<T> {
  return async function () {
    const r = await f();
    if (r === "unknownError") {
      throw new Error("unknownError");
    }
    return r;
  };
}

export function prepareDateForRequestDto(date: Dayjs) {
  return date.format("YYYY-MM-DDTHH:mm:ss");
}

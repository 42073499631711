class ClientiService {
  async getAll(): Promise<"unknownError" | Cliente[]> {
    try {
      const res = await fetch("/api/clienti");
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async get(id: number): Promise<"notFound" | "unknownError" | Cliente> {
    try {
      const res = await fetch(`/api/clienti/${id}`);
      if (res.status === 404) {
        return "notFound";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async create(
    dto: CreateOrUpdateClienteDto
  ): Promise<"unknownError" | "codiceDaneaAlreadyUsed" | Cliente> {
    try {
      const res = await fetch(`/api/clienti`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      if (res.status === 409) {
        return "codiceDaneaAlreadyUsed";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }

  async update(
    id: number,
    dto: CreateOrUpdateClienteDto
  ): Promise<"unknownError" | "codiceDaneaAlreadyUsed" | Cliente> {
    try {
      const res = await fetch(`/api/clienti/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dto),
      });
      if (res.status === 409) {
        return "codiceDaneaAlreadyUsed";
      }
      return await res.json();
    } catch (e) {
      return "unknownError";
    }
  }
}

export type Cliente = {
  id: number;
  codiceDanea: string | null;
  denominazione: string;
  codiceFiscale: string | null;
  partitaIva: string | null;
  indirizzo: string | null;
  cap: number | null;
  citta: string | null;
  deleted: boolean;
};

export type CreateOrUpdateClienteDto = {
  codiceDanea: string | null;
  denominazione: string;
  codiceFiscale: string | null;
  partitaIva: string | null;
  indirizzo: string | null;
  cap: number | null;
  citta: string | null;
  deleted: boolean;
};

export default new ClientiService();

import { useQuery } from "@tanstack/react-query";
import { unknownErrorToException } from "../services/adapters";
import ArticoliNonCatalogatiService from "../services/ArticoliNonCatalogatiService";

export default function useArticoliNonCatalogati() {
  const {
    isLoading,
    error,
    data: articoliNonCatalogati,
  } = useQuery({
    queryKey: ["articoliNonCatalogati"],
    queryFn: unknownErrorToException(() =>
      ArticoliNonCatalogatiService.getAll()
    ),
  });
  return {
    isLoading,
    error,
    articoliNonCatalogati,
  };
}

import { Autocomplete, TextField } from "@mui/material";
import useClienti from "../hooks/useClienti";
import { Cliente } from "../services/ClientiService";

export type ClientePickerProps = {
  value: number | null;
  onChange: (v: number | null) => void;
  disabled?: boolean;
};

export default function ClientePicker({
  value,
  onChange,
  disabled = false,
}: ClientePickerProps) {
  const { isLoading, error, clienti } = useClienti();
  const availableOptions = (clienti || []).map((c) => ({
    id: c.id,
    label: clienteToString(c),
    deleted: c.deleted,
  }));
  return (
    <Autocomplete
      size="small"
      disabled={isLoading || !!error || disabled}
      value={availableOptions.find((t) => t.id === value) || null}
      onChange={(e, v) => onChange(v?.id || null)}
      renderInput={(params) => <TextField {...params} label="Cliente" />}
      options={availableOptions.filter((o) => !o.deleted)}
      noOptionsText="Nessun cliente"
    />
  );
}

export function clienteToString(c: Cliente) {
  if (!c.codiceDanea) {
    return `${c.denominazione}`;
  }
  return `${c.denominazione} (${c.codiceDanea})`;
}
